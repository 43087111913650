import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
} from '@ionic/react';
import {
  NextButton,
  NextButtonLabel
} from "../../experience-details/ExperienceDetails.style";
import React, { useEffect, useState } from 'react';
import useTranslations from '../../../util/useTranslation';
import styled from 'styled-components';
import Collection from '../../../model/Collection';
import Item from '../../../model/Item';
import { getCollections, updateCollection } from '../../../util/API';
import { IonSpinner } from '@ionic/react';

const SaveItemToExistingCollectionModal: React.FC<{
  item?: Item;
  showModal: boolean;
  closeModal: () => void;
}> = ({ item, showModal, closeModal }) => {
  const [collections, setCollections] = useState<Collection[]>([]);
  const [dirtyCollections] = useState(new Set());
  const [t] = useTranslations('save-item');
  const [spinner, setSpinner] = useState<boolean>(false);

  useEffect(() => {
    const fetchCollections = async () => {
      setSpinner(true);
      const { data } = await getCollections();
      setSpinner(false);
      setCollections(data.collections);
    };

    fetchCollections();
  }, []);

  const onCheckboxChange = (collection: Collection) => {
    const itemId = item?.id as string;
    if (collection.items.includes(itemId)) {
      const itemIdx = collection.items.indexOf(itemId);
      collection.items.splice(itemIdx, 1);
    } else {
      collection.items.push(itemId);
    }

    dirtyCollections.add(collection.id);
  };

  const updateDirtyCollections = () => {
    collections
      .filter(({ id }) => dirtyCollections.has(id))
      .forEach(updateCollection);
    closeModal();
  };

  return (
    <IonContent>
     {spinner && (<div className="spinner">
        <IonSpinner color="primary" />
      </div>)}
      <Modal isOpen={showModal} onDidDismiss={closeModal}>
        <IonList>
          <IonListHeader>{t('modal.header')}</IonListHeader>
          <div className="collection-list">
          {collections.map((collection) => (
            <IonItem key={collection.id}>
              <IonLabel>{collection.title}</IonLabel>
              <IonCheckbox
                checked={collection.items.includes(item?.id as string)}
                onIonChange={() => onCheckboxChange(collection)}
              />
            </IonItem>
          ))}
          </div>
        </IonList>
        <IonItemGroup className='ion-align-self-end ion-padding btn-aligh'>
          {/* <IonButton onClick={closeModal}>Cancel</IonButton>
          <IonButton onClick={updateDirtyCollections}>Save</IonButton> */}
          <NextButton>
            <NextButtonLabel onClick={closeModal}>{t('option.cancel')}</NextButtonLabel>
          </NextButton>
          <NextButton>
          <NextButtonLabel onClick={updateDirtyCollections}>{t('option.save')}</NextButtonLabel>
          </NextButton>
        </IonItemGroup>
      </Modal>
    </IonContent>
  );
};

const Modal = styled(IonModal)`
  --height: 400px;
  --width: 400px;
  --border-radius: 0.25rem;

  .collection-list{
    height: 67%;
      overflow-y: auto;
  }
  .btn-aligh{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -153px auto 0;

  }
  // .modal-wrapper.sc-ion-modal-md{
  //   height:75%;
  // }

  .md.list-md.hydrated {
    height: 85%
  }
`;




export default SaveItemToExistingCollectionModal;
