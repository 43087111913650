import {
      IonButton,
      IonCheckbox,
      IonContent,
      IonItem,
      IonItemGroup,
      IonLabel,
      IonList,
      IonListHeader,
      IonModal,
      useIonPopover,
    } from '@ionic/react';
    import InputBase from '@material-ui/core/InputBase';
    import SearchIcon from '@material-ui/icons/Search';
    import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
    import React, { useEffect, useState } from 'react';
    import styled from 'styled-components';
    import Collection from '../../../model/Collection';
    import Item from '../../../model/Item';
    import { getCollections, updateCollection } from '../../../util/API';
    import { IonSpinner } from '@ionic/react';
    import { makeStyles } from '@material-ui/core/styles';
    import Drawer from '@material-ui/core/Drawer';
    import AppBar from '@material-ui/core/AppBar';
    import CssBaseline from '@material-ui/core/CssBaseline';
    import Toolbar from '@material-ui/core/Toolbar';
    import List from '@material-ui/core/List';
    import Typography from '@material-ui/core/Typography';
    import Divider from '@material-ui/core/Divider';
    import {
      ListItem,
      ListSubheader,
      ListItemText,
      ListItemIcon,
    } from '@material-ui/core';
    import InboxIcon from '@material-ui/icons/MoveToInbox';
    import MailIcon from '@material-ui/icons/Mail';
    import ClearIcon from '@material-ui/icons/Clear';
    import Button from '@material-ui/core/Button';
    import { IconButton } from '@material-ui/core';
    // import Button from '../../common/Button';
    import AddIcon from '@material-ui/icons/Add';
    
    import ItemsResponse from '../../../model/ItemsResponse';
    import { queryItems } from '../../../util/API';
    import useQuery, { QUERY_KEY } from '../../../util/useQuery';
    import useTranslations from '../../../util/useTranslation';
    import Header from '../../common/Header';
    import Filter from '../../search/Filter';
    import SearchInput from '../../search/Input';
    import SearchResultItemsList from '../../search/ItemsList';
    import ItemsList from '../../common/Item/List';
    
    import { TFunction } from 'react-i18next';
    import ItemCard from '../../common/Item/Card';
    import ItemCardFooter from '../../common/Item/CardFooter';
    import InputLabel from '@material-ui/core/InputLabel';
    import Select from 'react-select';
    import { Link } from 'react-router-dom';
    import FormControl from '@material-ui/core/FormControl';
    import FormGroup from '@material-ui/core/FormGroup'
    import NativeSelect from '@material-ui/core/NativeSelect';
    import { MultiSelect } from 'primereact/multiselect';
    
    import "primereact/resources/themes/saga-blue/theme.css";
    import "primereact/resources/primereact.min.css";
    import "primeicons/primeicons.css";
    import { AnyAaaaRecord } from 'dns';
    import UploadNewItem from '../../upload-new-Item/UploadNewItem'
    
    const drawerWidth = 240;
    
    const citySelectItems = [
      "dfdgf","r5tgryh","fghgyhj","yui"
    ];
    
    const useStyles = makeStyles((theme) => ({
      root: {
        display: 'flex',
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerContainer: {
        overflow: 'auto',
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
      button: {
        margin: theme.spacing(1),
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(4),
      },
      grow: {
        flexGrow: 1,
      },
      search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: '20ch',
        },
      },
    }));
    
    const ChooseSampleContentItem: React.FC<{
      item?: Item;
      showModal: boolean;
      multiSelect?: boolean;
      closeModal: (result : any) => void;
      onSelect: (item: Item) => any;
    }> = ({ item, showModal, closeModal, multiSelect, onSelect }) => {
      const classes = useStyles();
    
      // Search
      const [t] = useTranslations('search');
      const [cl] = useTranslations('collections');
      const [searchResult, setSearchResult] = useState<ItemsResponse | null>(null);
      const [results, setResults]=useState<any>([])
      const [Conbined,setConbined]=useState<any>([])
      const [collections, setCollections] = useState<Collection[]>([]);
      const [itemId, setitemId] = useState<number>(0);
      const [searchIn, setSearchIn] = useState<string>('');
      const [selectedSidebar, setSelectedSidebar] = useState('ExploreContent');
      const [spinner, setSpinner] = useState<boolean>(false);
      const [chooosenCount, setChooosenCount] = useState<boolean>(false);
      const [chooosenLanguages, setChooosenLanguages] = useState<any>([]);
      const [chooosenLocations, setChooosenLocations] = useState<any>([]);
      const [chooosenReuse, setChooosenReuses] = useState<any>([]);
      const [Language,setLanguage]=useState<any>([]);
      const [Location,setLocation]=useState<any>([]);
      const [Reuse,setReuse]=useState<any>([]);
      const [showUploadItem, setShowUploadItem] = useState(false)
      
      const { resetAllFilters, getQuery, setQuery, searchParams, filtersApplied } =
        useQuery(QUERY_KEY);
      const { clear, items: filterItems } = t('filter', {
        returnObjects: true,
      }) as {
        clear: string;
        items: Array<{ title: string; key: string; values: string[] }>;
      };
    
      const paramStr = searchParams().toString();
      
      const query = getQuery();
    //   useEffect(() => {
    //     const fetchResults = async () => {
    //       const { data } = await queryItems(paramStr);
    
    //       setSearchResult(() => data);
    //     };
    
    //     if (query) {
    //       fetchResults();
    //     } else {
    //       setSearchResult(() => null);
    //     }
    //   }, [paramStr, query]);
    
      useEffect(() => {
        const fetchCollections = async () => {
          setSpinner(true);
          const {
            data: { collections },
          } = await getCollections(true);
          setSpinner(false);
          setCollections(collections);
        };
    
        fetchCollections();

        let langugeList = filterItems.filter((i) => i.key == 'language').map((languages) =>languages.values);
        setLanguage(langugeList[0])
        
        let locationList = filterItems.filter((i) => i.key == 'media-type').map((lcn) =>lcn.values)
        setLocation(locationList[0])
    
        let ReuseList = filterItems.filter((i) => i.key == 'reuse').map((reuse) =>reuse.values)
        setReuse(ReuseList[0])
      
      }, []);
      // console.log("call results useeffect",searchResult);
      // var results = searchResult?.results.map((i:any)=>({...i,choose:false})) ?? [];
      
    
      const [state, setState] = React.useState<any>({
        language: '',
        location: '',
        period: '',
        query: '',
      });
      const [searchString, setSearchString] = useState<String>('')
    
    
      useEffect(() => {
        let query = "";
        for(const property in state){
          var nwstate = ""
          if(property == 'location'){
            nwstate = "media-type"
          }
          if(property == 'period') {
            nwstate = "reuse"
          }
            if(property !== 'query'){
              for(var i=0;i<state[property].length;i++)
              {
                if(query){
                    if(state[property]){
                        // query += '&'+property+'='+ state[property][i];
                        query += `&${nwstate ? nwstate : property}=${state[property][i]}`
                    } 
                } else {
                    if(state[property]){
                        // query += property+'='+state[property][i]
                        query += `&${nwstate ? nwstate : property}=${state[property][i]}`
                    } 
                }
              }
            }
            else{
            if(query){
                if(state[property]){
                    // query += '&'+property+'='+ state[property];
                    query += `&${nwstate ? nwstate : property}=${state[property]}`
                } 
            } else {
                if(state[property]){
                    // query += property+'='+state[property]
                    query += `&${nwstate ? nwstate : property}=${state[property]}`
                }
                
            }
          }
        }
    
        const fetchResults = async () => {
            setSpinner(true);
            const { data } = await queryItems(query);
            var res = data?.results.map((i:any)=>({...i,choose:false})) ?? [];
            setResults(res)
            setSpinner(false);
            setSearchResult(() => data);
          };
      
          if (query) {
            fetchResults();
          } else {
            setSearchResult(() => null);
          }
      }, [state])
    
      const handleChange = (events: any) => {
        const newValue:any=[];
        const name = events.target.name;

        if(!multiSelect){
          if(events.target.value && events.target.value.length){
            newValue.push(events.target.value[events.target.value.length-1]);   
          }
        } else {
          newValue.push(events.target.value);   
        }
       
        if (name == 'query' && events.key === 'Enter') { 
          // events.preventDefault();
          // return false;
            setState({
                ...state,
                [name]: events.target.value,
              }); 
          }
        if(name !== 'query'){
            setState({
                ...state,
                [name]: newValue,
              });     
          }
      };
    
      const searchClick = () => {
        setState({
          ...state,
          ['query']: searchString,
        });
      }
    
      const _filteredData=()=>
    {
     
      var choosenCollectionItems:any=[];
      var combine1:any=[];
      for(var i=0;i<collections.length;i++)
      {
        if(collections[i].item_details && collections[i].item_details.results && collections[i].item_details.results.length)
        {
          var items=collections[i].item_details.results.filter((item)=>item.choose==true)
          choosenCollectionItems.push(items)
          // setChooosenData(choosenCollectionItems.filter((i:any)=>i.length>0))
        }
      }
      var val1=choosenCollectionItems.filter((i:any)=>i.length>0)
      var val2=results.filter((i:any)=>i.choose==true)
      for(var i=0;i<val1.length;i++)
      {
         combine1.push(...val1[i].map((fg:any)=>fg));
      } 
      
      const combined=val2.concat(combine1);
      const ids = combined.map((o:any) => o.id)
      const filtered = combined.filter(({id}:any, index:number) => !ids.includes(id, index + 1))
      setConbined(filtered)
      closeModal(filtered) 
    }
     
    const selectLanguage = (e:any) =>{
      if(!multiSelect){
        if(e && e.length){
          setChooosenLanguages([e[e.length-1]]);
        }else{
          setChooosenLanguages(e);
        }
      } else {
        setChooosenLanguages(e);
      }
    }
    const selectLocation = (e:any) =>{
      // setChooosenLocations(e)
      if(!multiSelect){
        if(e && e.length){
          setChooosenLocations([e[e.length-1]]);
          console.log("[e[e.length-1]]",[e[e.length-1]])
        }else{
          setChooosenLocations(e);
          console.log("e1",e)
        }
      } else {
        setChooosenLocations(e);
        console.log("e2",e)
      }
    }
    const selectReuse = (e:any) =>{
      // setChooosenReuses(e)
      if(!multiSelect){
        if(e && e.length){
          setChooosenReuses([e[e.length-1]]);
        }else{
          setChooosenReuses(e);
        }
      } else {
        setChooosenReuses(e);
      }
    }

    const spnr = {
      top: "60%"
    }
   
    const ViewData=()=>
    {
      return(
        <div className={classes.root}>
        {spinner && (<div className="spinner" style={spnr}>
        <IonSpinner color="primary" />
         </div>)}
          <CssBaseline />
          <AppBar position='fixed' className={classes.appBar}>
            <Toolbar>
              <div className='Top-bar'>
                <Typography variant='h6' noWrap>
                  {t('choose.text')}
                </Typography>
                {
                  showModal==true?
                  <ClearIcon onClick={() => 
                  // closeModal(results)
                  _filteredData()
                  }></ClearIcon>
                  :''
                }
                
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant='permanent'
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <Button
                color='primary'
                className={classes.button}
                endIcon={<AddIcon />}
                onClick={() => setShowUploadItem(true)}
              >
                 {t('upload.text')}
              </Button>
              <div className='sidebar-List'>
                <List key="mainList">
                  <ListItem
                   key="exploreContent"
                    selected={
                      selectedSidebar == 'ExploreContent' ? true : false
                    }
                    onClick={() => setSelectedSidebar('ExploreContent')}
                  >
                    <ListItemText primary={t('header.headline')} />
                  </ListItem>
                  <ListItem
                    selected={(selectedSidebar == 'MyCollections' && !itemId) ? true : false}
                    key="collection"
                    onClick={() => {
                      setSelectedSidebar('MyCollections');
                      setitemId(0);
                    }}
                  >
                    <ListItemText primary={cl('header.headline')} />
                  </ListItem>
                  <List key="subList" className='sub-menu'>
                    {console.log("Collection:",collections)}
                  {collections.map((collection, i) => {
                    return (
                      collection &&
                      collection.title && (
                        <React.Fragment key={collection.id}>
                          <ListItem
                            selected={(selectedSidebar == 'MyCollections' && itemId == collection.id) ? true : false}
                            onClick={(e:any) => {setitemId(collection.id);setSelectedSidebar('MyCollections')}}
                            key={i.toString()}
                          >
                            <ListItemText primary={collection.title} />
                          </ListItem>
                          </React.Fragment>        
                      )
                    );
                  })}
                  </List>
                </List>
              </div>
            </div>
          </Drawer>
          <main className={classes.content}>
            <Toolbar />
            {/*------------------------------------- Explore Content ------------------------------------- */}
            {selectedSidebar == 'ExploreContent' && (
              <div className='exploreContent'>
                <div className='wide'>
                  <Header>
                    <h2>{t('header.headline')}</h2>
                    <div className="search-wrapper">
                      <div className={classes.search}>
                        {/* <SearchIcon onClick={()=>console.log("You Clicked")}/> */}
                        <IconButton className={classes.searchIcon} onClick={()=> console.log("Clicked")}>
                          <SearchIcon/>  
                        </IconButton> 
                        </div>
                        <InputBase
                          placeholder={t('input.placeholder')}
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          inputProps={{ 'aria-label': 'search' }}
                          name='query'
                          value={searchString}
                          onKeyDown={(e)=>handleChange(e)}
                          onChange={(e) => {handleChange(e); setSearchString(e.target.value)}}
                        />
                          <ArrowForwardIcon className="arow-btn" onClick={() => searchClick()}/>  
                      </div>
                  </Header>
                  <div className='filter'>
                    <Filters>
                      <FormGroup className={classes.formControl}>
                        <div className='filterlabel'>
                          <InputLabel
                            shrink
                            id='demo-simple-select-placeholder-label-label'
                          >
                           {t('filterby.text')}
                          </InputLabel>
                        </div>
                        
                        <div className='select-wrapper'>
                       <MultiSelect placeholder= {t('filterby.language')} name="language"  value={chooosenLanguages} options={Language} onChange={(e) => {selectLanguage(e.value);handleChange(e)}} />
                       <MultiSelect placeholder={t('filterby.type')}  name="location" value={chooosenLocations} options={Location} onChange={(e) => {selectLocation(e.value);handleChange(e)}} />
                       <MultiSelect placeholder={t('filterby.re_use')} name="period" value={chooosenReuse} options={Reuse} onChange={(e) => {selectReuse(e.value);handleChange(e)}} />
                        </div>
                      </FormGroup>
                    </Filters>
                  </div>
                  <SearchResultItemsList items={results} t={t} fromChooseContent={true} onSelectContent={onSelect}/>
                  {/* {!!results.length && (
                    <ViewedInfo className="footer">
                      <div className='info'>
                        {t('viewed.info', {
                          count: results.length,
                          total: searchResult?.total_count,
                        })}
                      </div>
                      <LoadMoreButtonWrapper>
                        <Button color='primary' className={classes.button}>
                          View More
                        </Button>
                      </LoadMoreButtonWrapper>
                    </ViewedInfo>
                  )} */}
                </div>
              </div>
            )}

            {/*-------------------------------------  My Collection  ------------------------------------- */}
            {selectedSidebar == 'MyCollections' && (
              <div className='myCollection'>
                <div className='wide'>
                  {collections.filter((i) => i.id == itemId).length > 0
                    ? collections
                        .filter((i) => i.id == itemId)
                        .map((item)=>
                            <SearchResultItemsList t={t} items={item.item_details?.results} fromChooseContent={true} onSelectContent={onSelect}></SearchResultItemsList>           
                          )
                    : collections.map((collection) => 
                          <SearchResultItemsList t={t} items={collection.item_details?.results} fromChooseContent={true} onSelectContent={onSelect}></SearchResultItemsList>
                      )}
                </div>
              </div>
            )}
          </main>
        </div>
      )
    }
    
      return (
      <div>
      {
       showModal==true?
        <IonContent>
          <Modal isOpen={showModal} onDidDismiss={() => _filteredData()}>
          {!showUploadItem && (ViewData())}
          {showUploadItem && (<UploadNewItem viewUploadModal={showUploadItem} closeUploadModal={() => setShowUploadItem(false)}/>)}
          </Modal>
        </IonContent>
        :
        <Container>
          {!showUploadItem && (ViewData())}
          {showUploadItem && (<UploadNewItem viewUploadModal={showUploadItem} closeUploadModal={() => setShowUploadItem(false)}/>)}
        </Container>
      }
      </div>
      );
    };
    
    const Container = styled.div`
    --height: 400px;
      --width: 400px;
      --border-radius: 0.25rem;
      --font-family: "Blooming Elegant", sans-serif;
      
      a,button,select,li,svg{
        cursor: pointer;
      }
    
      .modal-wrapper.sc-ion-modal-md {
        -webkit-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
        opacity: 0.01;
        width: 100vh;
        height: 80vh;
        background-color: #fefefe;
      }
      .Top-bar {
        display: flex;
       justify-content: space-between;
        width: 100%;
        align-items: center;
      }
      .MuiButton-containedPrimary {
        color: rgb(0 0 0 / 87%);
        background-color: #fac807;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 50px;
        margin: 15px auto !important;
        text-transform: capitalize;
      }
    
      .MuiDrawer-paperAnchorDockedLeft {
        height: auto !important ;
      }
    
      .MuiDrawer-paperAnchorDockedLeft > div:nth-child(2) {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        padding-top: 14px;
        background-color:#f7f7f7;
        height:100vh;
     }
      .MuiDrawer-paperAnchorDockedLeft > div:nth-child(2) .MuiButton-textPrimary {
        background-color: #fac807;
        color: #000;
        font-weight: 700;
        padding: 5px 20px;
        border-radius: 53px;
      }
     
    
      .sidebar-List .MuiListItem-gutters {
        padding-left: 38px;
      }
    
      .sidebar-List .MuiListItem-button {
        font-weight: 600;
        margin-bottom: 5px;
      }
    
      .sidebar-List .MuiListItem-button:hover {
        // background-color: #dee4f7;
        background-color: #dee4f6;
      }
      .active {
        color: #dee4f7;
        background-color: #dee4f6;
      }
    
      .sidebar-List .MuiListItem-button:focus-visible {
        background-color: #000;
        color: #fff;
      }
      .MuiTypography-displayBlock {
        display: block;
        font-size: 15px;
        font-weight: 700;
      }
          .sidebar-List .sub-menu .MuiTypography-displayBlock {
        font-weight: unset;
        font-size: 15px;
        text-transform: capitalize;
        color:#000;
      }
    
      .sidebar-List .sub-menu{
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .sidebar-List .sub-menu li{
        padding: 3px 17px 7px 38px;
      }
    
      .sidebar-List .sub-menu li:hover {
        background-color: #dee4f7;
        border-left: 6px solid #fac807;
      }
    
      .sidebar-List .MuiListItem-button:nth-child(3) .MuiTypography-displayBlock,
      .sidebar-List .MuiListItem-button:nth-child(4) .MuiTypography-displayBlock {
        font-size: 13px;
      }
      .MuiButton-containedPrimary:hover {
       background-color: #303f9f;
        color: #fff;
      }
    
      .wide:last-child div:nth-child(2) {
        margin-bottom: 0;
        margin-top: 0px;
      }
      .wide:last-child div:nth-child(2) span {
        font-weight: bold;
      }
    
      .search-wrapper{
          position:relative;
          // transform: translate(21%,-105%);
      }
    
      .search-wrapper .MuiSvgIcon-root{
        position: absolute;
        top: 41%;
        right: 10%;
        z-index: 1;
      }
    
      .search-wrapper div:nth-child(1) button{
        display: flex;
        padding: 0px 16px;
        position: absolute;
        align-items: center;
        pointer-events: none;
        justify-content: center;
        // right: 5%;
        // top: 22px;
        left: 12px;
        top: 15px;
     }
    
    
      .search-wrapper .MuiInputBase-input {
        width: 100% !important;
        padding: 18px 0 18px 54px;
       // margin-left: -30px;
        margin-right: 40px;
        background-color: #fff;
        // border: 1.5px solid #3f51b5;
        border-radius: 50px;
        font-size: 13px;
        color: #291d1d;
        font-weight: 600;
        box-shadow: 0px 0px 31px 2px #f5f5f7;
    
      }
    
    
      //----------ExploreContent
    
      .exploreContent header {
        display: flex;
        flex-direction: column;
       min-height: 130px;
        margin-top: -63px;
      }
          .exploreContent header h2 {
        font-size: 1.7rem;
      }
          .exploreContent header div {
        width: 100%;
        position: relative;
        margin-left: 0px !important;
      }
    
      .exploreContent .formControl {
        margin: 8px;
        min-width: 100% !important;
        position: relative;
        width: 100% !important;
      }
    
      .select-wrapper {
        height: 20px;
       display:flex;
        // transform: translate(23%,-50%);
       // transform: translate(19%,-180%)
      }
    
      .filterlabel label {
        font-size: 18px;
        font-weight: 700;
      }
    
      // .select-wrapper select {
      //   margin-top: 2px;
      //   margin-right: -21px;
      //   padding: 7px 42px 7px 19px;
      //   border-radius: 50px;
      //   border: 1px solid #0749ff;
      //   color: #0749ff;
      //   font-weight: 600;
      //   font-size: 14px;
     // }
    
      .select-wrapper .MuiInput-underline:before {
        content: ' ';
        border-bottom: none;
      }
    
      .MuiInput-underline:hover:not(.Mui-disabled):before,
      .MuiInput-underline:focus:not(.Mui-disabled):before {
        border-bottom: none;
        display: none;
      }
      .modal-wrapper.sc-ion-modal-md {
        -webkit-transform: translate3d(0, 40px, 0);
        -webkit-transform: translate3d(0, 40px, 0);
        -ms-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
        opacity: 0.01;
        width: 66%;
        height: 80%;
      }
    
      .wide {
        height: 100%;
      }
    
      main {
        height: 92% !important;
        margin-top: 64px;
        // overflow-y: auto;
        padding: 24px 15px;
        // background-color: #f1f0f0;
      }
    
      .ion-page>div{
        height:100%;
      }
    
      .filter + div {
        padding-top: 18px;
        // padding-left: 15px;
        margin-bottom: 9px;
        margin-top: 20px;
      }
    
      .search-results{
        gap: 4rem;
      }
      .search.md.hydrated{
        font-size: 9px;
      }
    
      .search-results>div {
        // width: 47% !important;
        width: 45% !important;
        // height: 12.75rem !important;
        height: 13.5rem !important;
        font-size: 1.375rem;
        line-height: 1.2;
      }
    
      .wide .info {
        margin-bottom: 0 !important;
      }
      .item-title-link {
        font-size: 13px;
       font-size: 14px;
        display: -webkit-box;
       max-width: 172px;
        /* max-height: 15px; */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .item-preview {
        width: 10rem;
        height: 8rem;
        object-fit: cover;
        border-radius: 16px;
      }
      .wide:last-child div:nth-child(2) .item-details-header {
        height: auto !important;
      }
      .item-title-wrapper {
        // height: 2.5em !important;
       height: 20% !important;
      }
    
    .item-details.hide .item-details-header.d-flex,
      .item-details.hide .item-details-header.d-flex div{
        display:none !important;
      }
    
      .search-wrapper {
        margin-top: 25px !important;
      }
    
      // .filterlabel+.select-wrapper {
      //   transform: translate(16%,-138%);
      // }
      .filterlabel+.select-wrapper {
        transform: translate(0%,-45%);
      }
    
      .modal-wrapper.sc-ion-modal-md{
       border-radius: 15px;
      }
    span.MuiButton-label{
      text-transform: capitalize;
    }
    .sidebar-List>ul li:nth-child(1),
    .sidebar-List>ul li:nth-child(2){
      text-transform: uppercase;
      color: #aab4ce;
    }
    
    .sidebar-List .MuiListItem-root.Mui-selected, 
    .sidebar-List .MuiListItem-root.Mui-selected:hover {
      background-color: #dee4f6;
      color: #000;
    }
    
    
    .sidebar-List {
      width: 100%;
      background-color: #f7f7f7;
    }
    
    svg.MuiNativeSelect-icon {
      color: rgb(13 60 247);
    }
    
    header.MuiPaper-root{
      background-color: #0c3cf7;
    }
    
    .arow-btn{
      position: absolute;
      top: 27% !important;
      right: 5% !important;
      z-index: 1;
     color: #0c3cf7;
    }
        -.MuiDrawer-docked {
      background-color: #f7f7f7;
    }
    
    .select-wrapper svg {
     display:none
    }
    .select-wrapper select{
      width: 110px;
      height: 17px;
      margin-top: 2px;
      margin-right: -20px;
      padding: 7px 42px 7px 19px;
      border-radius: 50px;
      border: 1px solid #0749ff;
      color: #0749ff;
      font-weight: 600;
      font-size: 14px;
    }
    .select-wrapper>div{
      position: relative;
      width: 12vw;
      margin-right: 15px;
      height: 2.15rem;
      border: 1px solid #0c3cf7;
      border-radius: 50px;
    }
    
    // .select-wrapper>div:after {
    //   content: "";
    //   position: absolute;
    //   top: 28%;
    //   right: 9px;
    //   width: 7px;
    //   height: 7px;
    //   border-width: 0px 1px 1px 0px;
    //   -webkit-transform: rotate(
    // 46deg
    // ) translate(-50%) skew(
    // 10deg
    // ,
    // 10deg
    // );
    //   -ms-transform: rotate(46deg) translate(-50%) skew(10deg,10deg);
    //   transform: rotate(
    // 33deg
    // ) translate(-50%) skew(
    // -12deg
    // ,
    // 13deg
    // );
    //   -webkit-transform-origin: left;
    //   -ms-transform-origin: left;
    //   transform-origin: left;
    //   border-style: solid;
    //   border-color: #0d3cf7;
    // }

    .filter .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row; 
      align-items: center;
  }
    
    //-----------Model Collection
    .myCollection .items>div{
      width: 29.6rem;
      height: 15rem;
      font-size: 1.75rem;
      line-height: 1.2;
    }
    .myCollection {
      margin-top: -74px;
    }
    .myCollection main {
      height: 92% !important;
      overflow-y: auto;
    }
    
    // New Select 
    .newsSelect span+div div:nth-child(2) {
     width:30px;
    }
    .newsSelect span+div{
      min-height: 33px;
    }
    
    .newsSelect svg{
      display:block !important;
    fill: #0849ff;
    line-height: 1;
    stroke: #0849ff;
    margin-top: 4px;
    }
    .newsSelect span+div div:nth-child(1) div{
      border-color: hsl(228deg 94% 51%);
      border-radius: 50px;
    }
    
    .select-wrapper>div div:nth-child(3) span:before{
      color: #0c3cf7;
      font-size: 11px;
      top: -2px;
      position: relative;
    }
    .p-multiselect .p-multiselect-label {
      padding: 6px 18px;
      font-size: 14px;
      color: #0c3cf7;
    }
     .arow-btn {
      position: absolute;
      top: 27% !important;
      right: 5% !important;
      z-index: 1;
      color: #0c3cf7;
  }
    
    //--------------- Media Query -------------------
    @media only screen and (max-width: 1920px) {
      .item-preview {
        height: 13rem;
    }
     .arow-btn {
      right: 4% !important;
    }
    }
      @media only screen and (max-width: 1440px) {
        .arow-btn {
          right: 5% !important;
        }
        .filter + div {
          padding-top: 18px;
          height: 355px;
        }
        .search-results .sc-hHEiqL {
          width: 18rem;
          height: 12.75rem !important;
          font-size: 13px;
          line-height: 1px;
        }
        .search-results .sc-hHEiqL .shadow {
          display: flex;
          flex-direction: column;
        }
        .item-preview {
          width: 10rem !important;
          // height: 160px !important;
          height: 18vh;
        object-fit: unset;
        }
        .item-details-header {
          height: 0;
        }
        .item-title {
          line-height: 14px;
          font-size: 12px;
          // text-align: center;
        }
        .item-title-wrapper {
          margin-bottom: 0em;
        }
        main {
          // height: 79% !important;
          // overflow-y: auto;
        }
        .wide .info {
          margin-bottom: -2px !important;
        }
        .makeStyles-root-28 {
          height: 100%;
        }
        .exploreContent {
          height: 100%;
        }
        .ion-page:first-child {
          height: 100% !important;
        }
        .MuiDrawer-paperAnchorDockedLeft {
          height: 100% !important;
        }
        .modal-wrapper.sc-ion-modal-md{
          width: 85%;
          height: 94%;
        }
        .exploreContent header .search-wrapper{
          margin-top: 29px !important;
        }
        .item-details-description {
          font-size: 14px;
          display: -webkit-box;
          max-width: 200px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .wide .footer{
          position: absolute;
          bottom: 0;
        width: 100%;
        background-color: #e6e8ed;
        padding: 13px 0 0;
        }
        .search-results>div {
          // width: 47% !important;
         width: 45% !important;
          height: 12rem !important;
        }
        .item-title-wrapper {
          height: 20% !important;
          margin-top: 9px !important;
          font-size: 14px;
          display: -webkit-box;
          max-width: 200px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        ion-button {
          font-size: 10px;
        }
        .search-results .shadow{
          gap:1rem;
          padding: 1rem;
        }
       .item-title-link {
          font-size: 13px;
      }
      .read-more-link{
        font-size: 10px;
      }
      .wide:last-child div:nth-child(2) {
        margin-bottom: 0;
        margin-top: 0px;
      }
    
     // My Collectio 
     .myCollection .items>div {
        width: 45%;
        height: 100%;
    }
    .myCollection .item-meta {
      text-transform: uppercase;
      font-size: 0.7rem;
    }
    .myCollection  .item-details-footer > div{
      display: flex;
      flex-direction: row;
    }
    myCollection  .item-details-page-link {
      height: 65%;
      font-size: 23px;
    }
    }
    
      @media only screen and (max-width: 1387px) {
        .search-results .sc-hHEiqL {
          width: 97%;
          height: 9.75rem;
        }
        .search-results .sc-hHEiqL .shadow {
          flex-direction: row;
        }
        .item-preview {
          width: 192px;
          height: 100%;
        }
      }
    
      @media only screen and (max-width: 1313px) {
        .select-wrapper > div {
          margin-top: 16px;
          margin-right: 12px;
        }
        .filterlabel label {
          font-size: 20px;
          margin-top: -12px;
        }
        .select-wrapper {
          transform: translate(-3%, -48%);
        }
        .select-wrapper select {
          margin-top: 6px;
          margin-right: -12px;
          padding: 3px 22px 7px 19px;
        }
        .wide .info {
          margin-bottom: -2px !important;
          font-size: 0.8rem;
        }
        .filterlabel+.select-wrapper {
          transform: translate(0%,-138%);
        }
        .filterlabel{
          margin-bottom: -7px;
        }
        .item-details-footer > div {
          display: flex;
          flex-direction: column;
        }
        .read-more-link {
          margin-top: -12px;
        }
      }
    
      @media only screen and (max-width: 1024px) {
        .modal-wrapper.sc-ion-modal-md {
          width: 85%;
          height: 90%;
        }
        .filter + div {
          padding-top: 45px;
          height: 51vh !important;
        }
       .item-details-footer > div {
          display: flex;
          flex-direction: row;
        }
       .search-results>div {
          width: 100% !important;
      }
     .item-title-wrapper,
     .item-title,
     .item-title-link {
        max-width: 100%;
       width:100%;
        text-align: left;
      }
      // My Collectio 
      .myCollection .items>div {
         width: 100%;
     }
      }
    
      @media only screen and (max-width: 768px) {
        .modal-wrapper.sc-ion-modal-md {
          position: relative;
        }
        .select-wrapper > div {
          margin-top: 25px;
          margin-right: 10px;
        }
    
        .select-wrapper select {
          margin-top: 3px;
          margin-right: -3px;
          padding: 2px 24px 7px 19px;
          font-size: 0.8rem;
        }
        .item-preview {
          width: 100%;
          height: 100%;
        }
        .search-results .sc-hHEiqL .shadow {
          flex-direction: column;
        }
        .search-results .sc-hHEiqL {
          width: 97%;
          height: 100% !important;
        }
        .read-more-link {
          margin-top: 0px;
        }
        .wide:last-child div:nth-child(2) {
          margin-bottom: 0;
          text-align: center;
        }
      }
    
      @media only screen and (max-width: 754px) {
        .select-wrapper > div {
          margin-top: 25px;
          margin-right: 0%;
        }
        .select-wrapper select {
          margin-top: 4px;
          margin-right: -3px;
          padding: 4px 24px 8px 19px;
          font-size: 0.7rem;
        }
      }
    
      @media only screen and (max-width: 634px) {
        .wide:last-child div:nth-child(2) {
          margin-bottom: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .select-wrapper > div {
         margin-top: 3px;
        }
        select-wrapper {
          margin-top: 21px;
          transform: translate(-16%, -48%);
        }
        .filter + div {
          padding-top: 17px;
          height: 51vh !important;
         padding-left: 5px;
          margin-top: 83px;
        }
        .item-details-footer > div {
          flex-direction: column;
        }
        .item-details-footer {
          height: 3rem;
          min-height: 3rem;
        }
        .wide:last-child div:nth-child(2) .item-title-wrapper {
          margin-bottom: 34px;
        }
        .read-more-link {
          margin-bottom: 16px;
        }
      }
      @media only screen and (max-width: 610px) {
        .select-wrapper {
          transform: translate(-18%, 5%);
        }
      }
    
      @media only screen and (max-width: 538px) {
        .select-wrapper {
          transform: translate(-7%, 134%);
        }
        .filterlabel label {
          font-size: 20px;
          margin-top: -2px;
          margin: 9px 7px;
        }
        .exploreContent header h2 {
          font-size: 1.3rem;
        }
       .exploreContent header {
          min-height: 98px;
        }
        main {
          padding: 13px;
        }
        .filter + div {
          padding-top: 14px;
          height: 51vh !important;
          padding-left: 5px;
          margin-top: 120px;
        }
        .cSURkI .item-title-link {
          font-size: 13px;
        }
      }
    
      @media only screen and (max-width: 425px) {
        .MuiDrawer-paperAnchorDockedLeft,
        .ion-page header + div {
          width: 148px;
        }
        .MuiButton-label {
          font-size: 6px;
        }
        .ebiyvr .MuiTypography-displayBlock {
          font-size: 10px;
        }
        .wide:last-child div:nth-child(2) span {
          font-size: 10px;
        }
        .MuiTypography-displayBlock {
          font-size: 11px;
        }
      }
      .item-details-footer>div .chooseMe{
        text-align: right;
      }
  `;
  

    const Modal = styled(IonModal)`
      --height: 400px;
      --width: 400px;
      --border-radius: 0.25rem;
      --font-family: "Blooming Elegant", sans-serif;
      
      a,button,select,li,svg{
        cursor: pointer;
      }
    
      .item-details-footer>div .chooseMe{
        text-align: right;
      }

.filter .MuiFormGroup-root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important; 
  align-items: center;
}
      .modal-wrapper.sc-ion-modal-md {
        -webkit-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
        opacity: 0.01;
        width: 100vh;
        height: 80vh;
        background-color: #fefefe;
      }
      .Top-bar {
        display: flex;
       justify-content: space-between;
        width: 100%;
        align-items: center;
      }
      .MuiButton-containedPrimary {
        color: rgb(0 0 0 / 87%);
        background-color: #fac807;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 50px;
        margin: 15px auto !important;
        text-transform: capitalize;
      }
    
      .MuiDrawer-paperAnchorDockedLeft {
        height: auto !important ;
      }
    
      .MuiDrawer-paperAnchorDockedLeft > div:nth-child(2) {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        padding-top: 14px;
        background-color:#f7f7f7;
        height:100vh;
     }
      .MuiDrawer-paperAnchorDockedLeft > div:nth-child(2) .MuiButton-textPrimary {
        background-color: #fac807;
        color: #000;
        font-weight: 700;
        padding: 5px 20px;
        border-radius: 53px;
      }
     
    
      .sidebar-List .MuiListItem-gutters {
        padding-left: 38px;
      }
    
      .sidebar-List .MuiListItem-button {
        font-weight: 600;
        margin-bottom: 5px;
      }
    
      .sidebar-List .MuiListItem-button:hover {
        // background-color: #dee4f7;
        background-color: #dee4f6;
      }
      .active {
        color: #dee4f7;
        background-color: #dee4f6;
      }
    
      .sidebar-List .MuiListItem-button:focus-visible {
        background-color: #000;
        color: #fff;
      }
      .MuiTypography-displayBlock {
        display: block;
        font-size: 15px;
        font-weight: 700;
      }
          .sidebar-List .sub-menu .MuiTypography-displayBlock {
        font-weight: unset;
        font-size: 15px;
        text-transform: capitalize;
        color:#000;
      }
    
      .sidebar-List .sub-menu{
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .sidebar-List .sub-menu li{
        padding: 3px 17px 7px 38px;
      }
    
      .sidebar-List .sub-menu li:hover {
        background-color: #dee4f7;
        border-left: 6px solid #fac807;
      }
    
      .sidebar-List .MuiListItem-button:nth-child(3) .MuiTypography-displayBlock,
      .sidebar-List .MuiListItem-button:nth-child(4) .MuiTypography-displayBlock {
        font-size: 13px;
      }
      .MuiButton-containedPrimary:hover {
       background-color: #303f9f;
        color: #fff;
      }
    
      .wide:last-child div:nth-child(2) {
        margin-bottom: 0;
        margin-top: 0px;
      }
      .wide:last-child div:nth-child(2) span {
        font-weight: bold;
      }
    
      .search-wrapper{
          position:relative;
          // transform: translate(21%,-105%);
      }
    
      .search-wrapper .MuiSvgIcon-root{
        position: absolute;
        top: 41%;
        right: 10%;
        z-index: 1;
      }
    
      .search-wrapper div:nth-child(1) button{
        display: flex;
        padding: 0px 16px;
        position: absolute;
        align-items: center;
        pointer-events: none;
        justify-content: center;
        // right: 5%;
        // top: 22px;
        left: 12px;
        top: 15px;
     }
    
    
      .search-wrapper .MuiInputBase-input {
        width: 100% !important;
        padding: 18px 0 18px 54px;
       // margin-left: -30px;
        margin-right: 40px;
        background-color: #fff;
        // border: 1.5px solid #3f51b5;
        border-radius: 50px;
        font-size: 13px;
        color: #291d1d;
        font-weight: 600;
        box-shadow: 0px 0px 31px 2px #f5f5f7;
    
      }
    
    
      //----------ExploreContent
    
      .exploreContent header {
        display: flex;
        flex-direction: column;
       min-height: 130px;
        margin-top: -63px;
      }
          .exploreContent header h2 {
        font-size: 1.7rem;
      }
          .exploreContent header div {
        width: 100%;
        position: relative;
        margin-left: 0px !important;
      }
    
      .exploreContent .formControl {
        margin: 8px;
        min-width: 100% !important;
        position: relative;
        width: 100% !important;
      }
    
      .filterlabel label {
        font-size: 18px;
        font-weight: 700;
      }
    
      // .select-wrapper select {
      //   margin-top: 2px;
      //   margin-right: -21px;
      //   padding: 7px 42px 7px 19px;
      //   border-radius: 50px;
      //   border: 1px solid #0749ff;
      //   color: #0749ff;
      //   font-weight: 600;
      //   font-size: 14px;
     // }
    
      .select-wrapper .MuiInput-underline:before {
        content: ' ';
        border-bottom: none;
      }
    
      .MuiInput-underline:hover:not(.Mui-disabled):before,
      .MuiInput-underline:focus:not(.Mui-disabled):before {
        border-bottom: none;
        display: none;
      }
      .modal-wrapper.sc-ion-modal-md {
        -webkit-transform: translate3d(0, 40px, 0);
        -webkit-transform: translate3d(0, 40px, 0);
        -ms-transform: translate3d(0, 40px, 0);
        transform: translate3d(0, 40px, 0);
        opacity: 0.01;
        width: 66%;
        height: 80%;
      }
    
      .wide {
        height: 100%;
      }
    
      main {
        // height: 92% !important;
        height: 88% !important;
        margin-top: 64px;
        overflow-y: auto;
        padding: 24px 15px;
        background-color: #f1f0f0;
      }
    
      .ion-page>div{
        height:100%;
      }
    
      .filter + div {
        padding-top: 18px;
        // padding-left: 15px;
        margin-bottom: 9px;
        margin-top: 20px;
      }
    
      .search-results{
        gap: 2rem;
      }
      .search.md.hydrated{
        font-size: 9px;
      }
    
      .search-results>div {
        width: 45% !important;
        // height: 12.75rem !important;
        height: 13.5rem !important;
        font-size: 1.375rem;
        line-height: 1.2;
      }
    
      .wide .info {
        margin-bottom: 0 !important;
      }
      .item-title-link {
        font-size: 13px;
       font-size: 14px;
        display: -webkit-box;
       max-width: 172px;
        /* max-height: 15px; */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .p-multiselect.p-component.p-inputwrapper{
        margin-top: 16px !important;
        margin-right: 12px !important;
      }
      .item-preview {
        width: 10rem;
        height: 8rem;
        object-fit: cover;
        border-radius: 16px;
      }
      .wide:last-child div:nth-child(2) .item-details-header {
        height: auto !important;
      }
      .item-title-wrapper {
        // height: 2.5em !important;
       height: 20% !important;
      }
    
    .item-details.hide .item-details-header.d-flex,
      .item-details.hide .item-details-header.d-flex div{
        display:none !important;
      }
    
      .search-wrapper {
        margin-top: 25px !important;
      }
    
      
      .filterlabel+.select-wrapper {
        transform: translate(0%,-45%);
      }
      .filterlabel{
        margin-top: -25px;
      }
      .modal-wrapper.sc-ion-modal-md{
       border-radius: 15px;
      }
    span.MuiButton-label{
      text-transform: capitalize;
    }
    .sidebar-List>ul li:nth-child(1),
    .sidebar-List>ul li:nth-child(2){
      text-transform: uppercase;
      color: #aab4ce;
    }
    
    .sidebar-List .MuiListItem-root.Mui-selected, 
    .sidebar-List .MuiListItem-root.Mui-selected:hover {
      background-color: #dee4f6;
      color: #000;
    }
    
    
    .sidebar-List {
      width: 100%;
      background-color: #f7f7f7;
    }
    
    svg.MuiNativeSelect-icon {
      color: rgb(13 60 247);
    }
    
    header.MuiPaper-root{
      background-color: #0c3cf7;
    }
    
    .arow-btn{
      position: absolute;
      top: 27% !important;
      right: 6% !important;
      z-index: 1;
     color: #0c3cf7;
    }
        -.MuiDrawer-docked {
      background-color: #f7f7f7;
    }
    
    .select-wrapper svg {
     display:none
    }
    .select-wrapper select{
      width: 110px;
      height: 17px;
      margin-top: 2px;
      margin-right: -20px;
      padding: 7px 42px 7px 19px;
      border-radius: 50px;
      border: 1px solid #0749ff;
      color: #0749ff;
      font-weight: 600;
      font-size: 14px;
    }
    .select-wrapper>div{
      position: relative;
      width: 12vw;
      margin-right: 15px;
      height: 2.15rem;
      border: 1px solid #0c3cf7;
      border-radius: 50px;
    }
    
    // .select-wrapper>div:after {
    //   content: "";
    //   position: absolute;
    //   top: 28%;
    //   right: 9px;
    //   width: 7px;
    //   height: 7px;
    //   border-width: 0px 1px 1px 0px;
    //   -webkit-transform: rotate(
    // 46deg
    // ) translate(-50%) skew(
    // 10deg
    // ,
    // 10deg
    // );
    //   -ms-transform: rotate(46deg) translate(-50%) skew(10deg,10deg);
    //   transform: rotate(
    // 33deg
    // ) translate(-50%) skew(
    // -12deg
    // ,
    // 13deg
    // );
    //   -webkit-transform-origin: left;
    //   -ms-transform-origin: left;
    //   transform-origin: left;
    //   border-style: solid;
    //   border-color: #0d3cf7;
    // }
    
    //-----------Model Collection
    .myCollection .items>div{
      width: 29.6rem;
      height: 15rem;
      font-size: 1.75rem;
      line-height: 1.2;
    }
    .myCollection {
      margin-top: -74px;
    }
    .myCollection main {
      height: 92% !important;
      overflow-y: auto;
    }
    
    // New Select 
    .newsSelect span+div div:nth-child(2) {
     width:30px;
    }
    .newsSelect span+div{
      min-height: 33px;
    }
    
    .newsSelect svg{
      display:block !important;
    fill: #0849ff;
    line-height: 1;
    stroke: #0849ff;
    margin-top: 4px;
    }
    .newsSelect span+div div:nth-child(1) div{
      border-color: hsl(228deg 94% 51%);
      border-radius: 50px;
    }
    
    .select-wrapper>div div:nth-child(3) span:before{
      color: #0c3cf7;
      font-size: 11px;
      top: -2px;
      position: relative;
    }
    .p-multiselect .p-multiselect-label {
      padding: 6px 18px;
      font-size: 14px;
      color: #0c3cf7;
    }
    
    
    //--------------- Media Query -------------------
      @media only screen and (max-width: 1440px) {
        .filter + div {
          padding-top: 18px;
          height: 355px;
        }
        .search-results .sc-hHEiqL {
          width: 18rem;
          height: 12.75rem !important;
          font-size: 13px;
          line-height: 1px;
        }
        .search-results .sc-hHEiqL .shadow {
          display: flex;
          flex-direction: column;
        }
        .item-preview {
          width: 10rem !important;
          // height: 160px !important;
          height: 18vh;
        object-fit: unset;
        }
        .item-details-header {
          height: 0;
        }
        .item-title {
          line-height: 14px;
          font-size: 12px;
          // text-align: center;
        }
        .item-title-wrapper {
          margin-bottom: 0em;
        }
        // main {
        //   height: 79% !important;
        //   overflow-y: auto;
        // }
        .wide .info {
          margin-bottom: -2px !important;
        }
        .makeStyles-root-28 {
          height: 100%;
        }
        .exploreContent {
          height: 100%;
        }
        .ion-page:first-child {
          height: 100% !important;
        }
        .MuiDrawer-paperAnchorDockedLeft {
          height: 100% !important;
        }
        .modal-wrapper.sc-ion-modal-md{
          width: 85%;
          height: 94%;
        }
        .exploreContent header .search-wrapper{
          margin-top: 29px !important;
        }
        .item-details-description {
          font-size: 14px;
          display: -webkit-box;
          max-width: 200px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .wide .footer{
          position: absolute;
          bottom: 0;
        width: 100%;
        background-color: #e6e8ed;
        padding: 13px 0 0;
        }
        .search-results>div {
          width: 47% !important;
          height: 12rem !important;
        }
        .item-title-wrapper {
          height: 20% !important;
          margin-top: 9px !important;
          font-size: 14px;
          display: -webkit-box;
          max-width: 200px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        ion-button {
          font-size: 10px;
        }
        .search-results .shadow{
          gap:1rem;
          padding: 1rem;
        }
       .item-title-link {
          font-size: 13px;
      }
      .read-more-link{
        font-size: 10px;
      }
      .wide:last-child div:nth-child(2) {
        margin-bottom: 0;
        margin-top: 0px;
      }
    
     // My Collectio 
     .myCollection .items>div {
        width: 47%;
        height: 100%;
    }
    .myCollection .item-meta {
      text-transform: uppercase;
      font-size: 0.7rem;
    }
    .myCollection  .item-details-footer > div{
      display: flex;
      flex-direction: row;
    }
    myCollection  .item-details-page-link {
      height: 65%;
      font-size: 23px;
    }
    }
    
      @media only screen and (max-width: 1387px) {
        .search-results .sc-hHEiqL {
          width: 97%;
          height: 9.75rem;
        }
        .search-results .sc-hHEiqL .shadow {
          flex-direction: row;
        }
        .item-preview {
          width: 192px;
          height: 100%;
        }
      }
    
      @media only screen and (max-width: 1313px) {
        .select-wrapper > div {
          margin-top: 16px;
          margin-right: 12px;
        }
        .filterlabel label {
          font-size: 20px;
          margin-top: -12px;
        }
        .select-wrapper {
          transform: translate(-3%, -48%);
        }
        .select-wrapper select {
          margin-top: 6px;
          margin-right: -12px;
          padding: 3px 22px 7px 19px;
        }
        .wide .info {
          margin-bottom: -2px !important;
          font-size: 0.8rem;
        }
     .filterlabel {
          margin-top: -14px;
      }
       
      }
    
      @media only screen and (max-width: 1313px) {
        .item-details-footer > div {
          display: flex;
          flex-direction: column;
        }
        .read-more-link {
          margin-top: -12px;
        }
      }
    
      @media only screen and (max-width: 1024px) {
        .modal-wrapper.sc-ion-modal-md {
          width: 85%;
          height: 90%;
        }
        .filter + div {
          padding-top: 45px;
          height: 51vh !important;
        }
       .item-details-footer > div {
          display: flex;
          flex-direction: row;
        }
       .search-results>div {
          width: 100% !important;
      }
     .item-title-wrapper,
     .item-title,
     .item-title-link {
        max-width: 100%;
       width:100%;
        text-align: left;
      }
      // My Collectio 
      .myCollection .items>div {
         width: 100%;
     }
      }
    
      @media only screen and (max-width: 768px) {
        .modal-wrapper.sc-ion-modal-md {
          position: relative;
        }
        .select-wrapper > div {
          margin-top: 25px;
          margin-right: 10px;
        }
    
        .select-wrapper select {
          margin-top: 3px;
          margin-right: -3px;
          padding: 2px 24px 7px 19px;
          font-size: 0.8rem;
        }
        .item-preview {
          width: 100%;
          height: 100%;
        }
        .search-results .sc-hHEiqL .shadow {
          flex-direction: column;
        }
        .search-results .sc-hHEiqL {
          width: 97%;
          height: 100% !important;
        }
        .read-more-link {
          margin-top: 0px;
        }
        .wide:last-child div:nth-child(2) {
          margin-bottom: 0;
          text-align: center;
        }
      }
    
      @media only screen and (max-width: 754px) {
        .select-wrapper > div {
          margin-top: 25px;
          margin-right: 0%;
        }
        .select-wrapper select {
          margin-top: 4px;
          margin-right: -3px;
          padding: 4px 24px 8px 19px;
          font-size: 0.7rem;
        }
      }
    
      @media only screen and (max-width: 634px) {
        .wide:last-child div:nth-child(2) {
          margin-bottom: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .select-wrapper > div {
         margin-top: 3px;
        }
        select-wrapper {
          margin-top: 21px;
          transform: translate(-16%, -48%);
        }
        .filter + div {
          padding-top: 17px;
          height: 51vh !important;
         padding-left: 5px;
          margin-top: 83px;
        }
        .item-details-footer > div {
          flex-direction: column;
        }
        .item-details-footer {
          height: 3rem;
          min-height: 3rem;
        }
        .wide:last-child div:nth-child(2) .item-title-wrapper {
          margin-bottom: 34px;
        }
        .read-more-link {
          margin-bottom: 16px;
        }
      }
      @media only screen and (max-width: 610px) {
        .select-wrapper {
          transform: translate(-18%, 5%);
        }
      }
    
      @media only screen and (max-width: 538px) {
        .select-wrapper {
          transform: translate(-7%, 134%);
        }
        .filterlabel label {
          font-size: 20px;
          margin-top: -2px;
          margin: 9px 7px;
        }
        .exploreContent header h2 {
          font-size: 1.3rem;
        }
       .exploreContent header {
          min-height: 98px;
        }
        main {
          padding: 13px;
        }
        .filter + div {
          padding-top: 14px;
          height: 51vh !important;
          padding-left: 5px;
          margin-top: 120px;
        }
        .cSURkI .item-title-link {
          font-size: 13px;
        }
      }
    
      @media only screen and (max-width: 425px) {
        .MuiDrawer-paperAnchorDockedLeft,
        .ion-page header + div {
          width: 148px;
        }
        .MuiButton-label {
          font-size: 6px;
        }
        .ebiyvr .MuiTypography-displayBlock {
          font-size: 10px;
        }
        .wide:last-child div:nth-child(2) span {
          font-size: 10px;
        }
        .MuiTypography-displayBlock {
          font-size: 11px;
        }
      }
    `;
    
    const Filters = styled.div`
      font-size: 0.875rem;
      display: flex;
    
      & > *:not(.clear) + *:not(.clear) {
        margin-left: 1rem;
      }
    
      .clear {
        text-decoration: underline;
        margin-left: auto;
        color: var(--ion-color-secondary);
        cursor: pointer;
        align-self: flex-start;
      }
    `;
    
    const ViewedInfo = styled.div`
      .info {
        margin-bottom: 1.75rem;
        color: var(--ion-color-secondary);
      }
    `;
    
    const LoadMoreButtonWrapper = styled.div`
      margin-bottom: 5rem;
    
      ion-button {
        --border-radius: 100px;
        text-transform: capitalize;
        font-weight: bold;
        font-size: 1rem;
      }
    `;
    
    
    export default ChooseSampleContentItem;