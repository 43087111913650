import { IonIcon } from '@ionic/react';
import { TFunction } from 'i18next';
import { arrowForward } from 'ionicons/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Item from '../../../model/Item';
import { ItemType } from '../../../model/ItemType';
import ItemCard from './Card';

const ItemsList: React.FC<{
  itemType: ItemType;
  title?: string;
  deleteItem?:(id : any)=>void;
  itemClicked?:() => void;
  items?: Item[];
  t: TFunction;
  add?: (options: any) => JSX.Element;
  viewItem?: (id: Item) => any;
}> = ({ itemType, title,deleteItem,  items, t, add, viewItem=(item) => `/app/search/items/${encodeURIComponent(item.id)}` }) => {
  const item = t(`items.${itemType}`);
  const item_plural = t(`items.${itemType}_plural`);
  const itemCardFooter = (item: any) => (
    <ItemCardFooterWrapper>
      <div className='item-meta'>{t('items.last_edited')}</div>
      {itemType && (
        <>
          <NavLink
            to={viewItem(item)}
            className='item-details-page-link'
          >
            <IonIcon icon={arrowForward} color='primary' />
          </NavLink>
        </>
      )}
    </ItemCardFooterWrapper>
  );

  return (
    <Root>
      <h3 className='title'>{title}</h3>
      {!items?.length && (
        <p className='no-items'>
          {t('items.noItems', { item: item_plural })}
        </p>
      )}
      <div className='items'>
        {items?.map((item) => {
          // deleteItem ? deleteItem(id) : null;
          return (
            <ItemCardWrapper key={item.id}>
              <ItemCard item={item}  deleteItem={(id) => deleteItem ? deleteItem(id) : null}  itemtype={itemType} footer={itemCardFooter(item)} viewItem={viewItem}></ItemCard>
            </ItemCardWrapper>
          );
        })}
        {add?.({ itemType, item })}
      </div>
    </Root>
  );
};

const Root = styled.div`
  margin-bottom: 5.25rem;

  h3,
  p {
    padding: 0;
    margin: 0;
  }

  .title {
    text-transform: uppercase;
    font-size: 1.25rem;
    line-height: 3rem;
    color: var(--ion-color-dark);
    font-weight: bold;
  }

  .no-items {
    color: var(--ion-color-secondary);
  }

  .items {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
`;

const ItemCardWrapper = styled.div`
  width: 36rem;
  height: 16rem;
  font-size: 1.75rem;
  line-height: 1.2;
`;

const ItemCardFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .item-meta {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--ion-color-secondary);
    align-self: flex-end;
  }

  .item-details-page-link {
    align-self: flex-end;
    height: 90%;
  }
`;

export default ItemsList;
