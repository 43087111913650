import React, { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';
import useTranslations from '../../util/useTranslation';
import CreateCard from '../common/CreateCard';
import { Col } from 'reactstrap';
import Header from '../common/Header';
import { useRouteMatch} from 'react-router';
import UploadNewItem from '../upload-new-Item/UploadNewItem';
import { getCollections,deleteCollectionItem} from '../../util/API';
import Itemlist from './RecommendCard';
import Button from '@material-ui/core/Button';
import NoImg from '../../assets/NoImage.png';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {
  IonItem,
  IonList,
  useIonPopover,
  IonLabel,
  IonButton,
  IonRouterLink,
} from '@ionic/react';
import { IonSpinner } from '@ionic/react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getItem, getRelatedItems,getCollectionList } from '../../util/API';
import { ImQuotesLeft } from 'react-icons/im';
import { ImQuotesRight } from 'react-icons/im';
import Item from '../../model/Item';

const PopoverList: React.FC<{
  onHide: () => void;
  translate: any[];
  onItemDelete?: () => void;
}> = ({ onHide, translate,onItemDelete}) => {
  const [t] = translate;
  return (
    <div className='xyz'>
      <StyledMenu>
        <IonList lines='none'>
          {/* <IonItem className="ionItem" routerLink={`/app/search/items/${encodeURIComponent(item.id)}`}>
            {t('menu.view')}    
            </IonItem>
            <IonItem className="ionItem" onClick={()=>{onHide()}}>
            {t('menu.edit')}     
            </IonItem>  */}
          <IonItem
            className='ionItem'
            onClick={() => (onItemDelete ? onItemDelete() : null, onHide())}
          >
            {t('menu.delete')}
          </IonItem>
        </IonList>
      </StyledMenu>
    </div>
  );
};

const StyledMenu = styled.div`
  .sc-ion-popover-md-h {
    --width: 200px !important;
  }
  a {
    text-decoration: none;
    color: var(--ion-color-secondary);
  }
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: 'flex',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CollectionItemList = (props) => {
  const [t] = useTranslations('search');
  const [ex] = useTranslations('experience')
 
  const [loadMore,setLoadMore] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);
  const { params } = useRouteMatch<{ itemId?: any}>();
  const [uploadModal,setuploadModal ] = useState<boolean>(false);
  let location=useLocation();
  const [clickedItem, setClickedItem] = useState<any>();
  const [Types, setTypes] = useState<any>([]);
  const [param,setParam] = useState<any>(location.state);
  const [dataCollection, setdataCollection] = useState<any>();
  const [allCollections, setallCollections] = useState<any>([]);
  const [option,setOption]=useState<string>(t('option_initial'));
  const { goBack } = useHistory();
  let history=useHistory();
  const [relatedItems, setRelatedItems] = useState<Item[]>([]);
  const {items: filterItems } = t('filterdropdown-item', {
    returnObjects: true,
  }) as {
    items: Array<{ id: string; title: string; value: string }>;
  };

  useEffect(() => {
    getCollectionData();
  }, []);


  const getCollectionData= async () =>
  {
    setSpinner(true);
    const {
      data: { collections },
    } = await getCollectionList(true,param.collectionId);
    
    setSpinner(false);
    
    if (collections.length) {
      setdataCollection(collections[0])
      setallCollections(collections[0]?.item_details?.results.slice(0,23));
      
      if(collections[0]?.item_details?.results)
      {   
        var latestTypes: any = [];
        collections[0]?.item_details?.results?.map((i)=> {
        let exist = latestTypes.findIndex((t : any) => t.name === i.type)
          if(exist !== -1){
            latestTypes[exist].count += 1
          } else{
            latestTypes.push({name: i.type,count:1})
            // setTypes([...Types,[{name: i.type,count:1}]])
          }
        });

        setTypes(latestTypes)
      }
      
      if(collections[0]?.items.length)
      {     
          const {
            data: { results },
          } = await getRelatedItems(collections[0]?.items[0]);
          setRelatedItems(results && results.length ? results.slice(0, 4) : []);
      }
    }
  }

  const cardClickAction=(id)=>
  {
    history.push({pathname:`/app/search/item-detail/${encodeURIComponent(id)}`}); 
  }

  const fetchFilteredData=(e)=>
  {
    //Display data according filter option click action with API call
    console.log("action clicked is",e);
  }

  const _onFilterChange = (event) => 
  {
      setOption(event.target.value);
      fetchFilteredData(event.target.value);
  }

  const _loadMoreClicked=()=>
  {
    setallCollections(dataCollection?.item_details?.results);
    setLoadMore(true);
  }
  const deleteItem= async (colId,item) =>
  {
    if (dataCollection && dataCollection.item_details && dataCollection.item_details.results.length) {       
      var itemIds: any = [];
      for (var i=0; i < dataCollection.item_details.results.length; i++) {
        if (dataCollection.item_details.results[i].id !== item.id) {
          itemIds.push(dataCollection.item_details.results[i].id)
        }
      }
      let request={
        id: dataCollection.id,
        description: dataCollection.description,
        title: dataCollection.title,
        preview_url: dataCollection.preview_url,
        items: itemIds,
        user_id: dataCollection.user_id
      }
      await deleteCollectionItem(request);
      getCollectionData();
    }  
  }

  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
    onItemDelete: () => (deleteItem ? deleteItem(dataCollection?.id,clickedItem) : null),
    translate: useTranslations('item'),
  });
  return (
    <div id='MyCollectionData'>
      {console.log("datacollection___",dataCollection?.item_details?.results?.map((i)=>i.type))}
      <div className='wide bg-gray' id='MyCollectionList'>
        {spinner && (
          <div className='spinner'>
            <IonSpinner color='primary' />
          </div>
        )}
        <div className='container'>
          <Header>
            <h2>
              <IconButton onClick={goBack}>
                <ArrowBackIcon className='default' />
              </IconButton>
            </h2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton aria-label='settings' className='view-more-content'>
                <MoreVertIcon />
              </IconButton>
            </div>
          </Header>
        </div>
        <div className='collection-heading bg-gray container'>
          <Typography variant='h2' display='block' gutterBottom>
            <ImQuotesLeft></ImQuotesLeft>
            {dataCollection?.title}
            <ImQuotesRight></ImQuotesRight>
          </Typography>
          <div className='collectionTag'>
            <Typography
              className='para'
              variant='caption'
              display='block'
              gutterBottom
            >
              {dataCollection?.description}
            </Typography>
            <div
              className='main-Collection-tag-wrapper'
              style={{ display: 'flex' }}
            >
              <span className='tag-title'>{t('collectiontag.tags')} :</span>
              <div className='tag-list'>
                {dataCollection?.tags?.length == 0 || dataCollection?.tags==null? (
                  <div style={{paddingLeft:"20px"}}>{t('notags.notags')}</div>
                ) : (
                  dataCollection?.tags?.map((tag) => <span>{tag}</span>)
                )}  
              </div>
            </div>
          </div>
        </div>
        <section>
          {/* Row - 1 */}
          <div className='main-content-wrapper'>
            <div className='heading container'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6' display='block' gutterBottom>
                  {t('inthiscollection.tags')}
                </Typography>
                {Types && Types.map(type => (
                  <div className='count'>
                    <span>{type?.count}</span>
                    <span>{type?.name}</span>
                  </div>
                  )
                )}
              </div>

              
              
              <div className="custom-select-wrapper">
                <span style={{ marginRight: '15px' }}>{t('filterBy.itemsBy')} : </span>
                <div className='select-box'>
                  <div
                    className='select-box__current'
                    onChange={_onFilterChange}
                    tabIndex={1}
                  >
                    {
                       filterItems?.map(({id,title,value },idx) => (
                        <div className='select-box__value'>
                        <input
                          className='select-box__input'
                          type='radio'
                          id={id}
                          value={value}
                          name='customSelect'
                          checked={option == value ? true : false}
                        />
                        <p className='select-box__input-text'>{title}</p>
                      </div>
                       ))}
                    <img
                      className='select-box__icon'
                      src='http://cdn.onlinewebfonts.com/svg/img_295694.svg'
                      alt='Arrow Icon'
                      aria-hidden='true'
                    />
                  </div>

                  <ul className='select-box__list'>
                  {
                    filterItems?.map(({id,title},idx) => (
                      <li>
                      <label className='select-box__option' htmlFor={id}>
                       {title}
                      </label>
                    </li>
                    ))
                  }
                  </ul>
                </div>
              </div>
            </div>
            <div className='row-content container'>
              <div className='row'>
                <div className='col'>
                  <Button
                    className='upload-btn'
                    style={{
                      backgroundColor: 'transparent',
                      textDecoration: 'none',
                      order: -1,
                    }}
                    onClick={()=>setuploadModal(true)}
                  >
                    <CreateCard>{t('upload.text')}</CreateCard>
                  </Button>
                </div>
                  {allCollections.map((item, index) => (
                    <Col>
                      <Card className='cards' onClick={()=>cardClickAction(item.id)}>
                        <div
                          style={{
                            position: 'relative',
                            backgroundColor: 'transparent',
                            border: ' none',
                          }}
                        >
                          <CardMedia
                            className='media'
                            image={item.preview_url?item.preview_url:NoImg}
                            title='Profile'
                          />
                          <CardActions className='viewMoreBtn'>
                            <IconButton
                             onClick={(e) => {
                              present({
                                event: e.nativeEvent,
                              });
                              e.stopPropagation();
                              setClickedItem(item);
                            }}
                              aria-label='settings'
                              className='viewMoreBtnItem'
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </CardActions>
                      
                        </div>
                        <CardHeader className='cardTitle' title={item.title} />
                      </Card>
                    </Col>
                  ))}
              </div>
            </div>
          </div>
        </section>
        <div style={{ backgroundColor: '#fff' }}>
         
           {
             (dataCollection?.item_details?.results.length>23 && loadMore==false)?
             <div className='displayMore container'>
              <span>
              {t('displayingcollection.text', {
                count: dataCollection?.item_details?.results.length
              })}
             </span>
             <Button onClick={()=>_loadMoreClicked()} className='loadMore' variant='contained'>
             <span>{t('loadMore.text')}</span>
             </Button>
             </div>
             :
             <div className='displayMore container'>
             <span>
             {t('collectioncount.text', {
                count: dataCollection?.item_details?.results.length
              })}
            </span>
            </div>

           }
        </div>

        {/* Related */}
        <div style={{ backgroundColor: '#fff', marginBottom: '150px' }}>
          <div className='related-post container'>
            <span>{t('recommend.text')}</span>
          </div>
          <div className='container'>
            <Itemlist t={t} items={relatedItems}></Itemlist>
          </div>
        </div>
      </div>
      {uploadModal && (
        <UploadNewItem viewUploadModal={uploadModal} closeUploadModal={() => setuploadModal(false)}/>
      )
      }
    </div>
  );
};

export default CollectionItemList;
