import React from 'react';
import { TFunction } from 'react-i18next';
import styled from 'styled-components';
import Item from '../../model/Item';
import ItemCard from '../common/Item/Card';
import ItemCardFooter from '../common/Item/CardFooter';

const SearchResultItemsList: React.FC<{
  items: Item[];
  mapCard?: Boolean;
  itemClickedIndex?:(index: any) => any
  t: TFunction<string[]>;
  fromChooseContent?: boolean,
  onSelectContent?: (item: Item) => any
}> = ({ items, t, mapCard, itemClickedIndex, fromChooseContent, onSelectContent }) => {
  return (
    <Styled>
      <div className='search-results'>
        {items?.map((item, index) => {
          return (
            <>
            <ItemCardWrapper key={item.id}>
              <ItemCard
                item={item}
                mapCard={mapCard ? mapCard : false}
                itemClicked={() => {
                  if(itemClickedIndex){
                    itemClickedIndex(index)
                  }
                 }}
                 footer={<ItemCardFooter item={item} t={t} fromChooseContent={fromChooseContent ? fromChooseContent : false} chooseButtonClick={(bool) => {
                  console.log("choose is",bool)
                  item['choose'] = bool
                  if (onSelectContent) {
                    onSelectContent(item);
                  }
                }} />}
              />
            </ItemCardWrapper>
            </>
          );
        })}
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  margin-top: 55px;
  margin-bottom: 70px;

  .search-results {
    display: flex;
    flex-wrap: wrap;
    gap: 2.3rem;
  }
`;

const ItemCardWrapper = styled.div`
  width: 29rem;
  height: 18.75rem;
  font-size: 1.375rem;
  line-height: 1.2;

  .item-details-header {
    visibility: hidden;
  }
`;

export default SearchResultItemsList;
