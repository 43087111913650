import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import useTranslations from '../../util/useTranslation';
import CreateCard from '../common/CreateCard';
import {listExperiences, deleteExperience} from '../../util/API';
import Header from '../common/Header';
import ItemsList from '../common/Item/List';
import { IonSpinner } from '@ionic/react';
import Item from '../../model/Item';
import { ItemType } from '../../model/ItemType';


const Dashboard = () => {
  const [t] = useTranslations('dashboard');

  // TODO: retrieve list of available experience types from configuration (stored in CMS)
  let [expTypes, setExpTypes] = useState<ItemType[]>(['tour', 'exhibit', 'story', 'longread', 'seminar']);
  let [exps, setExps] = useState([]);
  let [spinner, setSpinner] = useState(false);


  const fetchExperiences = async (exType: ItemType) => {
    setSpinner(true);
    const {
      data: { experiences },
    } = await listExperiences(exType);
    setSpinner(false);
    console.log("Fetched exps: --- ", experiences);
    setExps(exps => ({...exps, [exType]: experiences}));

  };

  useEffect(() => {
    expTypes.map((exType) => fetchExperiences(exType));
  }, []);

  const addNewItem = (options: any) => {
    const { itemType, item } = options;
    return (
      <NavLink to={`/app/${itemType}`} style={{ textDecoration: 'none' }}>
        <CreateCard>
          {t('items.new')} {item}
        </CreateCard>
      </NavLink>
    );
  };

  const viewItem = (item: Item) => {
    return `${item.view_url}`;
  }

  const listListsItems = expTypes.map((exType, i) => 
          <ItemsList
            itemType={`${exType}`}
            key={i}
            title={`${t(`items.${exType}_plural`)}`}
            items={exps[exType]}
            t={t}
            add={addNewItem}
            viewItem={viewItem}
            deleteItem={deleteExperience}
          />    

      );

  return (
    <div className='container wide'>
     
      {spinner ==true? (<div className="spinner">
        <IonSpinner color="primary" />
      </div>)
    :(
      <div>
      <Header>
        <h2>{t('header.headline')}</h2>
      </Header>
      {listListsItems}  
      </div>
    )  
    }
    
    </div>
  );
};

export default Dashboard;
