import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import Collections from '../components/collections/Collections';
import Footer from '../components/common/Footer';
import NavBar from '../components/common/NavBar';
import Dashboard from '../components/dashboard/Dashboard';
import ItemDetails from '../components/details/ItemDetails';
import Search from '../components/search/Search';
import ItemDetailsPage from '../components/newcollections/ItemDetails';
import Collection from '../components/newcollections/Collection';
import StyledPage from '../components/styled/Page';
import useTranslations from '../util/useTranslation';
import NewExperience from '../components/new-experience/NewExperience';
import CollectionDetail from '../components/newcollections/CollectionItemList';
import ExperienceDetails from '../components/experience-details/ExperienceDetails';
import SearchWidgetModal from '../components/search-widget/SearchWidgetModal';
import SearchWidget from '../components/search-widget/SearchWidget';
import ItemWidget from '../components/item-widget/ItemWidget';



const Root = () => {
  const [t] = useTranslations('common');
  const { path } = useRouteMatch();

  return (
    <IonPage>
      <IonContent>
        <StyledPage>
          <NavBar t={t} hideForRoute={[`${path}/search-widget`, `${path}/item-widget`]} />
          <Switch>
            <Route path={`${path}/dashboard`} component={Dashboard} />
            <Route path={`${path}/search`} exact component={Search} />
            <Route path={`${path}/search/items/:id+`} component={ItemDetails} />
            <Route path={`${path}/collections`} component={Collections} />
            <Route path={`${path}/collection`} component={Collection} />
            <Route path={`${path}/search/item-detail/:id+`} component={ItemDetailsPage} />
            <Route path={`${path}/collectionDetail/:id+`} component={CollectionDetail} />
            <Route path={`${path}/new-experience`} component={NewExperience}/>
            <Route path={`${path}/tour`} component={ExperienceDetails}/>
            <Route path={`${path}/exhibit`} component={ExperienceDetails}/>
            <Route path={`${path}/seminar`} component={ExperienceDetails}/>
            <Route path={`${path}/story`} component={ExperienceDetails}/>
            <Route path={`${path}/longread`} component={ExperienceDetails}/>
            <Route path={`${path}/search-widget-modal`} component={SearchWidgetModal}/>
            <Route path={`${path}/search-widget`} component={SearchWidget}/>
            <Route path={`${path}/item-widget`} component={ItemWidget}/>
            <Redirect from={path} to={`${path}/dashboard`} />
          </Switch>
          <Footer hideForRoute={[`${path}/search-widget`, `${path}/item-widget`]} />
        </StyledPage>
      </IonContent>
    </IonPage>
  );
};

export default Root;
