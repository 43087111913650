import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { ImGooglePlus } from 'react-icons/im';
import { ImFacebook } from 'react-icons/im';
import { ImTwitter } from 'react-icons/im';
import useTranslations from '../../util/useTranslation';
import Link from '@material-ui/core/Link';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { IonCol,IonRow } from '@ionic/react';
import ClearIcon from '@material-ui/icons/Clear';
import {
  ExperienceContent,
  FieldSet,
  Legend,
  LegendContainer,
  LegendLeftTitle,
  LegendSeparator,
} from './Style';

import { IonContent, IonModal } from '@ionic/react';

const SignIn = () => {
  const [open, setOpen] = React.useState(true);
  const [t] = useTranslations('authentication');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState('');
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const submitSignIn = () => {
    if (!pattern.test(email) || password.length < 8) {
      alert(t('signinvalidation.invalid'));
    } else {
      alert(t('signinvalidation.success'));
    }
  };

  return (
    <Styled>
      <div className='signup-Model-wrap'>
        {/* <Button variant='outlined' color='primary' onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}

        {open && (
          <div className='xyz'>
            <IonContent>
              <Modal isOpen={open}>
                <div className='canclebtn'>
                  <ClearIcon onClick={() => setOpen(false)}></ClearIcon>
                </div>
                <div className='d-flex title'>
                  <h5>{t('signin.text')}</h5>
                  <span>
                  {t('or.text')}
                    <Link href='/home/signup'>{t('newaccount.text')}</Link>
                  </span>
                </div>
                <div className='conten-wrapper'>
                  <Button
                    variant='outlined'
                    color='primary'
                    startIcon={<ImGooglePlus />}
                  >
                    <span
                      style={{
                        textTransform: 'none',
                        color: '#114fff',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('socialauth.google')}
                    </span>
                  </Button>
                  <Button
                    variant='outlined'
                    color='primary'
                    startIcon={<ImFacebook />}
                  >
                    <span
                      style={{
                        textTransform: 'none',
                        color: '#114fff',
                        fontWeight: 'bold',
                      }}
                    >
                       {t('socialauth.facebook')}
                    </span>
                  </Button>
                  <Button
                    variant='outlined'
                    color='primary'
                    startIcon={<ImTwitter />}
                  >
                    <span
                      style={{
                        textTransform: 'none',
                        color: '#114fff',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('socialauth.twitter')}
                    </span>
                  </Button>
                  <span className='single-line'></span>
                </div>

                <div className='field-wrap'>
                  <IonRow>
                    <IonCol>
                      <FieldSet
                        style={
                          !pattern.test(email) && email.length
                            ? { border: '1px solid rgb(234 5 5)' }
                            : { border: '1px solid rgba(113,124,156,0.5)' }
                        }
                      >
                        <Legend>
                          <LegendContainer>
                            <LegendLeftTitle>{t('fields.email')}</LegendLeftTitle>
                            <LegendSeparator
                              className='separator'
                              style={
                                !pattern.test(email) && email.length
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: 'rgba(113,124,156,0.5)' }
                              }
                            ></LegendSeparator>
                          </LegendContainer>
                        </Legend>
                        <ExperienceContent
                          placeholder={t('placeholder.email')}
                          type='text'
                          id='email'
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </FieldSet>
                    </IonCol>
                  </IonRow>
                  {email == '' ? null : !pattern.test(email) ? (
                    <label className='Errorlabel'> {t('errorlabel.email')}</label>
                  ) : null}
                  <IonRow>
                    <IonCol>
                      <FieldSet
                        style={
                          password.length < 8 && password.length
                            ? { border: '1px solid rgb(234 5 5)' }
                            : { border: '1px solid rgba(113,124,156,0.5)' }
                        }
                      >
                        <Legend>
                          <LegendContainer>
                            <LegendLeftTitle> {t('fields.password')}</LegendLeftTitle>
                            <LegendSeparator
                              className='separator'
                              style={
                                password.length < 8 && password.length
                                  ? { backgroundColor: 'rgb(234 5 5)' }
                                  : { backgroundColor: 'rgba(113,124,156,0.5)' }
                              }
                            ></LegendSeparator>
                          </LegendContainer>
                        </Legend>
                        <ExperienceContent
                          className='password-input'
                          placeholder={t('placeholder.password')}
                          type='password'
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          maxLength={12}
                        />
                      </FieldSet>
                    </IonCol>
                  </IonRow>
                  {password.length == 0 ? null : password.length < 8 ? (
                    <label className='Errorlabel'>
                      {t('errorlabel.password')}
                    </label>
                  ) : null}
                  <Link className='forgotPswd' href='#'>
                  {t('forgotpswd.text')}
                  </Link>
                </div>

                <div className='d-flex footer-wrap signin'>
                  <Button
                    className='signupbtn'
                    onClick={() => submitSignIn()}
                    variant='contained'
                    color='primary'
                    endIcon={<ArrowRightAltIcon />}
                  >
                   {t('signin.button')}
                  </Button>
                </div>
              </Modal>
            </IonContent>
          </div>
        )}
      </div>
    </Styled>
  );
};

const Styled = styled.div``;

const Modal = styled(IonModal)`
  // --height: 80%;
  --height: 510px;
  --width: 400px;
  justify-content: center !important;
  padding: 40px 0;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  .canclebtn {
    text-align: right;
    width: 96%;
    padding: 17px 0px 0 0;
    margin-bottom: -29px;
  }

  .canclebtn svg {
    fill: #8b7f9e;
  }

  .ion-page {
    justify-content: inherit;
    overflow: auto;
    padding-bottom: 35px;
    background-color: #fff;
  }

  .ion-page::-webkit-scrollbar {
    display: none;
  }

  .modal-wrapper.sc-ion-modal-md {
    border-radius: 17px;
  }
  .Errorlabel {
    color: red;
    display: flex;
    justify-content: flex-end;
    margin: -3px 6px 3px;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .title h5 {
    font-weight: 700;
    color: #5a5a5a;
  }

  .title span a {
    color: #757f9e;
    margin-left: 5px;
    text-decoration: underline;
    font-weight: 600;
    font-size: 15px;
  }
  .title span {
    color: #757f9e;
    margin-left: 5px;
    font-weight: 600;
    font-size: 15px;
  }
  .conten-wrapper {
    display: flex;
    flex-direction: column;
  }
  .conten-wrapper .single-line {
    width: 76%;
    height: 1px;
    background-color: #5a5a5a;
    margin: 7px auto 0;
  }
  .forgotPswd {
    padding-left: 8px;
    text-decoration-line: underline;
    font-size: 13px;
    font-weight: 600;
    color: #99a1b8;
  }
  .conten-wrapper button {
    border-radius: 50px;
    width: 80%;
    margin: 0 auto 10px;
  }
  .conten-wrapper .MuiButton-startIcon {
    margin-left: 8px;
    margin-right: 8px;
  }

  .conten-wrapper .MuiButton-label {
    display: flex;
    justify-content: end;
  }
  .conten-wrapper button:nth-child(2) .MuiButton-label {
    padding: 0 53px 0 0;
    color: rgb(17, 79, 255);
  }
  .conten-wrapper button:nth-child(1) .MuiButton-label {
    padding: 0 65px 0 0;
    color: rgb(17, 79, 255);
  }
  .conten-wrapper button:nth-child(3) .MuiButton-label {
    padding: 0 73px 0 0;
    color: rgb(17, 79, 255);
  }

  .conten-wrapper .MuiButton-label span:nth-child(2) {
    margin-left: 39px;
  }

  .field-wrap {
    width: 80%;
    margin: 10px auto 16px;
  }

  fieldset input {
    padding: 0;
    height: 100%;
    font-size: 14px;
  }

  fieldset {
    margin: 0 auto 0px;
    border: 1px solid #ccc;
  }

  .separator {
    margin: 7px 0 0 0;
  }

  .footer-wrap {
    justify-content: flex-end;
    width: 75%;
    margin: 7px auto 15px;
  }
  .footercheckbox {
    display: flex;
    flex-direction: inherit;
    align-items: center;
    font-size: 13px;
  }

  .footercheckbox .MuiSvgIcon-fontSizeSmall {
    font-size: 15px;
  }

  .footercheckbox .MuiFormControlLabel-label {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .footercheckbox a.MuiLink-root.MuiLink-underlineHover {
    font-size: 12px;
    font-size: 12px;
    text-decoration: underline;
    bottom: -180px;
    margin-left: 5px;
    font-size: 13px;
  }

  .signupbtn {
    color: white;
    background-color: rgb(17, 79, 255);
    font-size: 12px;
    font-weight: 600;
    text-transform: none;
    border-radius: 50px;
  }
  .signupbtn:hover {
    color: white;
    background-color: rgb(17, 79, 255);
  }

  @media only screen and (min-width: 1024px) {
    .footercheckbox a.MuiLink-root.MuiLink-underlineHover {
      bottom: -180px;
    }
  }
  @media only screen and (max-width: 375px) {
    .conten-wrapper .MuiButton-label span:nth-child(2) {
      margin-left: 16px;
    }
    .conten-wrapper button:nth-child(1) .MuiButton-label {
      padding: 0 50px 0 0;
    }
    .conten-wrapper .MuiButton-startIcon {
      margin-right: 25px;
    }
    .conten-wrapper button:nth-child(2) .MuiButton-label {
      padding: 0px 36px 0 0;
    }
    .conten-wrapper button:nth-child(3) .MuiButton-label {
      padding: 0 57px 0 0;
    }
  }

  @media only screen and (max-width: 360px) {
    .conten-wrapper button:nth-child(1) .MuiButton-label {
      padding: 0 31px 0 0;
    }
    .conten-wrapper button:nth-child(2) .MuiButton-label {
      padding: 0 14px 0 0;
    }
    .conten-wrapper button:nth-child(3) .MuiButton-label {
      padding: 0 36px 0 0;
    }
    .footer-wrap{
      flex-direction: column;
    }
    .footercheckbox{
      flex-direction: row;
      margin-bottom:15px;
    }
  }
`;

export default SignIn;
