import { heart } from 'ionicons/icons';
import React, { SyntheticEvent, useState } from 'react';
import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Item from '../../../model/Item';
import Button from '../Button';
import SaveItemActions, { SaveItemActionsState } from '../SaveItem/Actions';
import { IonButton, IonIcon } from '@ionic/react';

const ItemCardFooter: React.FC<{ item: Item; t: TFunction<string[]>; 
  fromChooseContent?:boolean; 
  chooseButtonClick?:(choose : any) => void;
  viewItem?: (item: Item) => any;}> = ({
                                        item,
                                        t,
                                        fromChooseContent,
                                        chooseButtonClick,
                                        viewItem=(item) => `/app/search/items/${encodeURIComponent(item.id)}`
}) => {
  const [saveActionsState, setSaveActionsState] =
    useState<SaveItemActionsState>({
      item,
      event: undefined,
      dismiss: () =>
        setSaveActionsState((prev) => ({ ...prev, event: undefined })),
    });

  const showSaveActions = (e: SyntheticEvent) =>
    setSaveActionsState((prev) => ({
      ...prev,
      event: e.nativeEvent,
    }));

  // TODO change to check if saved
  const saved = false;
  const [chooseItem, setChooseItem] = useState<boolean>(item.choose ? item.choose : false)
  return (
    <>
      <ItemCardFooterWrapper>
        <Link
          className='read-more-link'
          to={viewItem(item)}
        >
          {t('results.more', { ns: 'search' })}
        </Link>

        {fromChooseContent ?
        <SaveItemButtonWrapper className="chooseMe" onClick={() => {
          let choose = !chooseItem
          setChooseItem(choose);
          if(chooseButtonClick){
            chooseButtonClick(choose);
          }
          
        }}>
          {/* <Button
            text={t(`results.${saved ? 'saved' : 'save'}`, {
              ns: 'search',
            })}
            size='small'
            color='primary'
            fill={saved ? 'solid' : 'outline'}
            icon={heart}
          /> */}
          <IonButton size='small'  fill={chooseItem ? 'solid' : 'outline'}>
          {t('results.choose', { ns: 'search' })}
          </IonButton>
        </SaveItemButtonWrapper>
        :
        <SaveItemButtonWrapper onClick={showSaveActions}>
          <Button
            text={t(`results.${saved ? 'saved' : 'save'}`, {
              ns: 'search',
            })}
            size='small'
            color='primary'
            fill={saved ? 'solid' : 'outline'}
            icon={heart}
          />
        </SaveItemButtonWrapper>
      }
        
      </ItemCardFooterWrapper>
      <SaveItemActions {...saveActionsState} />
    </>
  );
};

const ItemCardFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  justify-content: space-between;

  .read-more-link {
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const SaveItemButtonWrapper = styled.div`
  height: 100%;
  min-width: 50%;

  ion-button {
    --border-radius: 100px;
    text-transform: unset;
    font-size: inherit;
    font-weight: bold;
  }
`;

export default ItemCardFooter;
