import styled from 'styled-components';
import useTranslations from '../../util/useTranslation';

const Brand: React.FC = () => {
  const [t] = useTranslations('home');

  return (
    <StyledBrand>
      <div className='d-flex ion-align-items-center'>
        <img
          className='logo'
          src={`${process.env.PUBLIC_URL}/assets/logo/jhn.png`}
          alt='JHN'
        />
        <div className='project'>
          <div className='project__name'>{t('header.headline')}</div>
          <div className='project__attribution'>
          {t('brand')}
          </div>
        </div>
      </div>
    </StyledBrand>
  );
};

const StyledBrand = styled.div`
  color: #fff;
  text-decoration: none;

  .logo {
    width: clamp(46px, 8vw, 65px);
    margin-right: clamp(13px, 2vw, 20px);
  }

  .project {
    font-weight: bold;

    &__name {
      font-size: clamp(26px, 4vw, 38px);
    }

    &__attribution {
      font-size: clamp(14px, 2vw, 22px);
    }
  }
`;

export default Brand;
