import React, { useEffect, useState } from 'react';
import Collection from '../../model/Collection';
import { getCollections,deleteCollectionItem} from '../../util/API';
import useTranslations from '../../util/useTranslation';
import Header from '../common/Header';
import CollectionList from './CollectionList';
import { IonSpinner } from '@ionic/react';
import {useHistory} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display:'flex',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

const Collections = () => {
  const [t] = useTranslations('collections');
  const [collections, setCollections] = useState<Collection[]>([]);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [option,setOption]=useState<string>(t('option_initial'));
  const itemsPerPage = 4;
  const [page, setpage] = useState<number>(1);
  let history=useHistory();
 
  const {items: filterItems } = t('filterdropdown-collection', {
    returnObjects: true,
  }) as {
    items: Array<{ id: string; title: string; value: string }>;
  };


  const fetchCollections = async () => {
   
    setSpinner(true);
    const {
      data: { collections },
    } = await getCollections(true);
    setSpinner(false);
    setCollections(collections);
  };

  const fetchFilteredData=(e)=>
  {
    //Display data according filter option click action with API call
    console.log("action clicked is",e);
  }

  useEffect(() => {
    fetchCollections();
    fetchFilteredData(option);
  }, []);

  const classes = useStyles();

  const handleChange = (event) => 
  {
      setOption(event.target.value);
      fetchFilteredData(event.target.value);
  };

  const onPageChange=(event, value)=>
  {
      setpage(value);
  } 

  const cardClickAction=(clicked,data,id,collectionId)=>
  {
    history.push({pathname:`/app/search/item-detail/${encodeURIComponent(id)}`,state:{collectionId:collectionId}}); 
  }

   const _deleteItem= async (collectionId, itemId)=> {
  
    const collection:any = collections.find(task => task.id == collectionId);
    console.log("COllection id is",collection)
    if (collection && collection.item_details && collection.item_details.results.length) {       
      var itemIds: any = [];
      for (var i=0; i < collection.item_details.results.length; i++) {
        if (collection.item_details.results[i].id !== itemId) {
          itemIds.push(collection.item_details.results[i].id)
        }
      }
      let request={
        id: collection.id,
        description: collection.description,
        title: collection.title,
        preview_url: collection.preview_url,
        items: itemIds,
        user_id: collection.user_id
      }
      await deleteCollectionItem(request);
      fetchCollections();
    }  
  }

  return (
    <div className='wide' id="MyCollectionList">
      {spinner && (<div className="spinner">
        <IonSpinner color="primary" />
      </div>)}
       <div>
        <div className='container'>
        <Header>
          <h2>{t('header.headline')}</h2>
          <div className="custom-select-wrapper">
            <span style={{marginRight:'15px'}}>{t('filterBy.collectionsBy')} : </span>
            <div className="select-box">
                <div className="select-box__current"  onChange={handleChange} tabIndex={1}>
                 
                  {
                    filterItems?.map(({id,title,value },idx) => (
                      <div className="select-box__value">
                      <input className="select-box__input" type="radio" id={id} value={value} name="customSelect" checked={option == value ? true : false}/>
                      <p className="select-box__input-text">{title}</p>
                    </div>
                    ))
                  }
                  <img className="select-box__icon" src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"/>
                </div>
                
                <ul className="select-box__list">
                {
                    filterItems?.map(({id,title},idx) => (
                      <li>
                    <label className="select-box__option" htmlFor={id} >{title}</label>
                  </li>
                    ))
                  }
                </ul>
              </div>

          </div>
        </Header>
        </div>
       
        {collections.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((collection) => (
            <CollectionList
              key={collection.id}
              colId={collection.id}
              deleteItem={(id) => _deleteItem(collection.id,id)}
              cardClicked={(clicked,data,id)=>cardClickAction(clicked,data,id,collection.id)}
              title={collection.title}
              totalDatalength={collection.item_details?.total_count}
              items={collection.item_details?.results}
            />
          ))}
       
        <div className="pagination-section container">
          <div>
          <Pagination hidePrevButton={page==1?true:false} hideNextButton={page==Math.ceil(collections.length/itemsPerPage)?true:false} onChange={onPageChange}  count={Math.ceil(collections.length/itemsPerPage)} page={page} defaultPage={1}  color="primary" />
          </div>
        </div> 
        </div>  
    </div>
  );
};

export default Collections;


