import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import React from 'react';
import SignInPage from './components/authentication/SignIn';
import SignUPPage from './components/authentication/SignUp';
import { Redirect, Route } from 'react-router-dom';
import GlobalStyle from './components/styled/GlobalStyle';
import Home from './pages/Home';
import Root from './pages/Root';
import './theme/variables.scss';

const App: React.FC = () => {
  return (
    <IonApp>
      <GlobalStyle />
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path='/home' component={Home} />
          <Route path='/app' component={Root} />
          <Route path={`/home/signin`} component={SignInPage}/>
          <Route path={`/home/signup`} component={SignUPPage}/>
          <Route render={() => <Redirect to='/home' />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
