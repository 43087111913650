import React, { memo, useEffect, useState, useRef } from 'react';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import Itemlist from '../newcollections/RecommendCard';
import ItemsResponse from '../../model/ItemsResponse';
import { queryItems } from '../../util/API';
import useQuery, { QUERY_KEY } from '../../util/useQuery';
import useTranslations from '../../util/useTranslation';
import Button from '../common/Button';
import ToggleButton from '../common/ToggleButton';
import Header from '../common/Header';
import Filter from './Filter';
import SearchInput from './Input';
// import SearchResultItemsList from './ItemsList';
import  {LatLng, LatLngBounds, Layer}  from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { Card, Icon, Image } from "semantic-ui-react";
import L from 'leaflet'
import { IonLabel, IonSpinner } from '@ionic/react';
import 'leaflet/dist/leaflet.css';

// import icon from 'leaflet/dist/images/marker-icon.png';
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import icon from '../../assets/icon/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const Search = () => {
  
  const { path } = useRouteMatch();
  const history = useHistory();
  
  console.log("Dashboard link path ::---",history.location)
  const [t] = useTranslations('search');
  const [searchResult, setSearchResult] = useState<ItemsResponse | null>(null);
  const { resetAllFilters, getQuery, setQuery, searchParams, filtersApplied, setGeoLocation, getGeoLocation } =
    useQuery(QUERY_KEY);

  const paramStr = searchParams().toString();
  const query = getQuery();
  const geolocationQuery = getGeoLocation();

  // console.log("paramStr:::---",paramStr);
  // console.log("query ::----",query);
  // console.log("geolocationQuery ::-----",geolocationQuery)
  
  const [showMap, setShowMap] = useState(geolocationQuery == 'true' ? true : false);
  const [clearSearchData,setClearSearchData]= useState<boolean>(query ? true : false)
  const [markerIndex, setMarkerIndex] = useState<any>(null)
  const [map, setMap] = useState<any>(null);
  const [spinner, setSpinner] = useState<boolean>(false);

 

  const { clear, items: filterItems } = t('filter', {
    returnObjects: true,
  }) as {
    clear: string;
    items: Array<{ title: string; key: string; values: string[] }>;
  };

  
  
  // setGeoLocation('false')
  console.log("filterItems ::----",query)
  useEffect(() => {
    const fetchResults = async () => {
      setSpinner(true)
      const { data } = await queryItems(paramStr);
      setSpinner(false)

      setSearchResult(() => data);
    };

    if (query || geolocationQuery) {
      fetchResults();
    } else {
      setSearchResult(() => null);
    }
  }, [paramStr, query]);

  useEffect(() => {
    if(markerIndex > 0){

      let Item : any = itemsWithLocation[markerIndex-1]
      map.flyTo(new LatLng(Item.location[0].lat,Item.location[0].lon),13)
    }
  },[markerIndex]);

  const results : any = searchResult?.results ?? [];
  let itemsWithLocation = searchResult?.results ?? [];

  const ResizeMap = (props :any ) => {
    const { bounds } = props;
    const map1 : any = useMap();
  
    map1._onResize();
    map1.setZoom(2);
    return null;
  }

  const renderMap = () => {
    if (!showMap || !results.length) {
      return;
    }

    itemsWithLocation = results.filter(({location} : any) => location && (location.length > 0));
    
    const bounds = (itemsWithLocation.length && new LatLngBounds(itemsWithLocation.reduce((acc: any , {location:[{lat, lon}] } : any) => acc.push([lat, lon]) && acc, []))) || undefined;
    console.log("Bounds::---",bounds);
    console.log("map::--",map)
    return (bounds && 
      <div>
        {<MapContainer 
           whenCreated={setMap}
           style={{ height: "400px" }}
           bounds={bounds}
          //  center={[43.38621, -79.83713]} 
           zoom={12}
           scrollWheelZoom={true}
        >
          <ResizeMap bounds={bounds}/>
          <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

          { itemsWithLocation.map((item : any, index: any) => (
              <PointMarker item={item} index={index}/>
            ))}
        </MapContainer>}
        
      </div>
    )

  }

  const PointMarker = (props : any) => {
    const markerRef = useRef<any>(null);
    const { item, index } = props;
    return(
      <Marker
        position={[item.location[0].lat, item.location[0].lon]}
        key={item.id}
      >
        <Popup>
          <Mapcard>
            <div className="map-card">
          <Card
            image={
              (
                <>
                <Image src={item.preview_url}/>
                {item.location && (
                  <a
                    target="_blank"
                    href={`http://www.openstreetmap.org/?mlat=${item.location[0].lat}&mlon=${item.location[0].lon}&zoom=17&layers=M`}
                    // alt={item.title}
                    title="See at openstreetmap.org"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer noopener"
                  >
                    <Icon
                      size="large"
                      className="location-marker"
                      name="map marker alternate"
                      inverted
                      color="grey"
                    />
                  </a>
                )}
                </>
              ) || "http://placehold.it/200"
            }
            header={
              <Link
                to={{
                  pathname: `${path}/items/${encodeURIComponent(item.id)}`,
                  state: { item: item },
                }}
                className="header"
                title={item.title}
              >
                {item.title}
              </Link>
            }
            title={item.description}
            description={item.description}
            extra={item.tags?.map((tag:any, i:any, { length }:any) => (
              <React.Fragment key={i}>
                <Link
                  title={`Search similar by tag: ${tag}`}
                  to={`/search?query=${tag}&page=1`}
                >
                  {tag}
                </Link>
                {i + 1 < length ? ", " : ""}
              </React.Fragment>
            ))}
          />
          </div>
          </Mapcard>
        </Popup>
      </Marker> 
    )
  }

  useEffect(() => {
    const actualQuery = searchParams();
    //  setQuery(query? query :'  ')
         
    // setTimeout(()=>{
    //   history.push({ search: actualQuery.toString()});
    // },200)
  },[])

  return (
    <div className='container wide new-search'>
      {spinner && (<div className="spinner">
        <IonSpinner color="primary" />
      </div>)}
      
      <Header>
        <h2>{t('header.headline')}</h2>
        <SearchInput
          {...{
            getQuery,
            setQuery,
            placeholder: t('input.placeholder'),
          }}
          clearSearch={clearSearchData}
        />
      </Header>
      <div className="newSearchFilter">
        <Filters  className="filter-wrap">
          {filterItems?.map(({ title, key: filterKey, values }, idx) => (
          <div className="filter-items">
            <Filter
                {...{ title, filterKey, values }}
                key={`filter${title}${idx}`}
              />
          </div>
          ))}
          {filtersApplied() && (
            <div onClick={resetAllFilters} className='clear'>
              {clear}
            </div>
          )}
        </Filters>
      </div>
      
      {query && (
        <MainToggle className="list-shwo-hide">
          <div>
            <IonLabel>{t('result_found', {
                count: showMap ? itemsWithLocation.length : results.length,
                total: searchResult?.total_count,
              })} "{query}"</IonLabel> <a href="javascript:void(0)" onClick={() => {
              setGeoLocation('')
              setQuery('');
              setClearSearchData(!clearSearchData);

              setTimeout(() => {
                setShowMap(false)
              }, 500);
              
            }}><b>{t('reset')}</b></a>
          </div>
          <div style={{float:'right', display:'flex'}}>
            <IonLabel class="toggle-label" color={!showMap? 'primary':'secondary'}>{t('list')}</IonLabel>
            <ToggleButton checked={showMap ? true : false} toggleChange={((e:any) => {
              
              e ? setGeoLocation('true') : setGeoLocation('false')
              setTimeout(() => {
                setShowMap(e ? e : false);
              }, 800);
            })} />
            <IonLabel class="toggle-label" color={showMap? 'primary':'secondary'}>{t('map')}</IonLabel>
          </div>
        </MainToggle>
      )}
      
        
      {(query && showMap) ?
      <div className="map-content-wrapper">
      {renderMap()}
      <div>
        {/* <SearchResultItemsList items={itemsWithLocation} mapCard={true} t={t} itemClickedIndex={(i) => setMarkerIndex(i+1)} /> */}
        <Itemlist t={t} items={itemsWithLocation}  mapCard={true} itemClickedIndex={(i) => setMarkerIndex(i+1)}></Itemlist> 
        {/* {!!itemsWithLocation.length && (
          <ViewedInfo>
            <div className='info'>
              {t('viewed.info', {
                count: itemsWithLocation.length,
                total: searchResult?.total_count,
              })}
            </div>
            <LoadMoreButtonWrapper>
              <Button text={t('viewed.more')} color='warning' />
            </LoadMoreButtonWrapper>
          </ViewedInfo>
        )} */}
      </div>
    </div>
      :
      <div>
        {/* <SearchResultItemsList items={results} t={t} /> */}
        <Itemlist t={t} items={results}></Itemlist> 
        {!!results.length && (
          <ViewedInfo>
            <div className='info'>
              {t('viewed.info', {
                count: results.length,
                total: searchResult?.total_count,
              })}
            </div>
            <LoadMoreButtonWrapper>
              <Button text={t('viewed.more')} color='warning' />
            </LoadMoreButtonWrapper>
          </ViewedInfo>
        )}
      </div>
      }
      
      
    </div>
  );
};

const Filters = styled.div`
  font-size: 0.875rem;
  display: flex;

  & > *:not(.clear) + *:not(.clear) {
    margin-left: 1rem;
  }

  .clear {
    text-decoration: underline;
    margin-left: auto;
    color: var(--ion-color-secondary);
    cursor: pointer;
    align-self: flex-start;
  }

  .button-inner{
    display: block !important;
        width: 72px !important;
        height: 13px !important;
        z-index: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
  }

`;

const ViewedInfo = styled.div`
  .info {
    margin-bottom: 1.75rem;
    color: var(--ion-color-secondary);
  }
`;

const LoadMoreButtonWrapper = styled.div`
  margin-bottom: 5rem;

  ion-button {
    --border-radius: 100px;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1rem;
  }
`;

const MainToggle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  .toggle-label {
    margin-top: 12px;
    font-weight: 600;
    font-size: 14px;
  }
`

const Mapcard = styled.div`
.map-card .ui{
  width: 12rem;
  height: 100%;
}
.map-card .ui.image{
  border: 0;
  width: 12rem;
 height: 8rem;
  object-fit: cover;
  border-radius: 16px;
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 4px !important;
  right: 2px !important;
}
.header {
  color: #0849ff;
  font-size: 20px !important;
  text-transform: capitalize;
  line-height: inherit;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 21px;
  font-family: 'Source Sans Pro',serif;
  text-decoration: none;
}

.map-card .description{
  color: var(--ion-color-secondary);
  font-size: 17px;
  line-height: 1.5;
  font-family: 'Source Sans Pro';
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.extra.contet{
      color: #0849ff;
      font-size: 14px !important;
      text-transform: capitalize;
      line-height: 1.6;
      font-weight: bold;
      word-wrap: break-word;
      font-size: 21px;
      font-family: 'Source Sans Pro',serif;
}

`;

export default memo(Search);