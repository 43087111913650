import styled from 'styled-components';
import useTranslations from '../../../util/useTranslation';
import CommonSection from './Common';
import Section from './Model';

const { PUBLIC_URL } = process.env;

const ForWhoSection: React.FC = () => {
  const id = 'for-who';
  const [t] = useTranslations('home');

  const { headline, items } = t(id, {
    returnObjects: true,
  }) as Section;

  return (
    <StyledSection id={id}>
      <div className='container'>
        <h2 className='headline ion-text-center'>{headline}</h2>
        <div className='items'>
          {items.map(({ icon, title, description }, idx) => (
            <div
              className='item d-flex d-flex-col ion-align-items-center ion-text-center'
              key={`${id}-section-item-${idx}`}
            >
              {icon && (
                <img src={`${PUBLIC_URL}${icon}`} alt={title} className='img' />
              )}
              <h3 className='title'>{title}</h3>
              <p className='description'>{description}</p>
            </div>
          ))}
        </div>
      </div>
    </StyledSection>
  );
};

const StyledSection = styled(CommonSection)`
  .headline {
    color: var(--ion-color-dark);
  }

  .item {
    .title {
      color: var(--ion-color-primary);
    }

    .description {
      color: var(--ion-color-dark);
    }
  }
  @media screen and (min-width: 1024px) {
    .items {
      display: flex;
      justify-content: space-between;
    }

    .item {
      width: 21%;
    }

    .item + .item {
      margin-top: 0;
    }
  }
`;

export default ForWhoSection;
