import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const DEFAULT_LANG = 'en';

const useTranslations = (...i18ns: string[]) => {
  const location = useLocation();
  const { t, i18n } = useTranslation<string[]>(i18ns);
  const [lang, setLang] = useState(DEFAULT_LANG);

  useEffect(() => {
    const langParam =
      new URLSearchParams(location.search)?.get('lang') ?? DEFAULT_LANG;
    setLang(langParam);
    i18n.changeLanguage(langParam);
  }, [i18n, location.search]);

  return [t, lang] as const;
};

export default useTranslations;
