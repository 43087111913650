import { IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';
import React from 'react';
import styled from 'styled-components';

const CreateCard: React.FC = ({ children }) => {
  return (
    <Root className="upload-file-wrapper">
      <IonIcon className='add' icon={add} />
      <div className='text'>{children}</div>
    </Root>
  );
};

const Root = styled.div`
  text-transform: uppercase;
  width: 18rem;
  min-height: 16rem;
  color: var(--ion-color-secondary);
  font-size: 1.125rem;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--ion-color-secondary);
  border-radius: 1rem;
  cursor: pointer;

  ion-icon.add {
    font-size: 6rem;
  }

  .text {
    width: 80%;
    text-align: center;
  }
`;

export default CreateCard;
