import axios, { AxiosResponse } from 'axios';
import Collection from '../model/Collection';
import CollectionsResponse from '../model/CollectionsResponse';
import ItemsResponse from '../model/ItemsResponse';
import Experience from '../model/Experience'
import { ItemType } from '../model/ItemType';
import ExperiencesResponse from '../model/ExperiencesResponse';

// TODO: extract to .env
const API_URL = 'https://dj-story.jhn.ngo/api/v1';
//const API_URL = 'http://localhost:6060/api/v1';
const ITEMS_PATH = '/items';
const RECOMMEND_PATH = '/recommend';
const COLLECTIONS_PATH = '/collections';
const EXPERIENCES_PATH = "/experiences";
const TAGS_PATH = "/tags"
const DEFAULT_SOURCE = "JHN"


const http = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

const queryItems = async (
  query: string
): Promise<AxiosResponse<ItemsResponse>> => {
  return await http.get<ItemsResponse>(`${ITEMS_PATH}?${query}&source=${DEFAULT_SOURCE}`);
};

const getItem = async (id: string): Promise<AxiosResponse<ItemsResponse>> => {
  // Not using {params: {id}} because axios will encode slashes in id once again
  return await http.get<ItemsResponse>(`${ITEMS_PATH}?id=${id}`);
};

const getRelatedItems = async (
  id: string
): Promise<AxiosResponse<ItemsResponse>> => {
  return await http.get<ItemsResponse>(`${RECOMMEND_PATH}?item_id=${id}`);
};

const getCollections = async (withDetails: boolean = false) => {
  return await http.get<CollectionsResponse>(
    `${COLLECTIONS_PATH}?details=${withDetails ? 1 : 0}`
  );
};

const updateCollection = async (collection: Collection) => {
  return await http.post(COLLECTIONS_PATH, {
    payload: collection,
  });
};

const createCollection = async (request: Collection) => {
  return await http.post(COLLECTIONS_PATH, {
    payload: request
  })
}

const deleteCollectionItem = async (collection: any) => {
  return await http.post(COLLECTIONS_PATH, {
    payload: collection,
  });
}

const addUpdateExperience = async (exhibit : Experience) => {
  return await http.post(EXPERIENCES_PATH, {
    payload: exhibit
  });
}
const uploadNewItem = async (request: Collection) => {
  return await http.post(COLLECTIONS_PATH, {
    payload: request
  });
}

const getExperience = async (expId: any) => {
  return await http.get(`${EXPERIENCES_PATH}?id=${expId}`);
}

const listExperiences = async (type?: any) => {
  return await http.get<ExperiencesResponse>(`${EXPERIENCES_PATH}?type=${type}`);
}


const deleteExperience = async (itemId) => {
   return await http.delete(`${EXPERIENCES_PATH}?id=${itemId}`);
}
const getCollectionList = async (withDetails: boolean = false,collectionId) => {
  return await http.get<CollectionsResponse>(
    `${COLLECTIONS_PATH}?id=${collectionId}&details=${withDetails ? 1 : 0}`
  );
};
const getTags = async (query : string) => {
  return await http.get(`${TAGS_PATH}?${query}`);
}

export {
  queryItems,
  getItem,
  getRelatedItems,
  getCollections,
  getCollectionList,
  uploadNewItem,
  updateCollection,
  createCollection,
  addUpdateExperience,
  listExperiences,
  getExperience,
  deleteExperience,
  getTags,
  deleteCollectionItem
};