import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CommonSection from './Common';
import Section from './Model';

const { PUBLIC_URL } = process.env;

const PartnersSection = () => {
  const { t } = useTranslation(['home']);

  const { headline, items } = t('partners', {
    returnObjects: true,
  }) as Section;

  return (
    <Partners id='partners' className='strips'>
      <div className='items container'>
        <div className='headline d-flex ion-justify-content-center ion-align-items-center'>
          {headline}
        </div>
        {items.map(({ icon, title }, idx) => (
          <div
            className='item d-flex ion-justify-content-center ion-align-items-center'
            key={`partners-section-item-${idx}`}
          >
            <img src={`${PUBLIC_URL}${icon}`} alt={title} className='img' />
          </div>
        ))}
      </div>
    </Partners>
  );
};

const Partners = styled(CommonSection)`
  padding: 2em 0;

  .headline {
    color: var(--ion-color-dark);
    margin: 0;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .item {
    width: 50%;
    min-height: 4rem;
  }

  @media screen and (min-width: 1024px) {
    .items {
      justify-content: space-evenly;
    }

    .item {
      width: 21%;
    }

    .item + .item {
      margin-top: 0;
    }
  }
`;

export default PartnersSection;
