import styled from 'styled-components';
import NavMenuComponentProps from '../../interfaces/NavMenuComponentProps';

const DesktopNavMenu: React.FC<NavMenuComponentProps> = ({
  navMenuItems,
  scrollToSection,
}) => {
  return (
    <Menu className='d-flex ion-hide'>
      {navMenuItems.map(({ id, text }) => (
        <li className='item' key={`desktop-menu-item-${id}`}>
          <span className='link' onClick={() => scrollToSection(id)}>
            {text}
          </span>
        </li>
      ))}
    </Menu>
  );
};

const Menu = styled.ul`
  list-style: none;
  padding: 0;

  font-size: clamp(0.8em, 1.8vw, 1.25em);
  font-weight: 600;

  .item + .item {
    margin-left: clamp(1em, 2.5vw, 2.5em);
  }

  .link {
    padding: 0.25em;
    transition: color 0.5s;

    &:hover {
      cursor: pointer;
      color: var(--ion-color-warning);
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex !important;
  }
`;

export default DesktopNavMenu;
