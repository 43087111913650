import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';

const Button: React.FC<{
  text: string;
  color?: 'primary' | 'warning' | 'light';
  fill?: 'clear' | 'outline' | 'solid';
  size?: 'default' | 'small' | 'large';
  icon?: string;
  buttonOnPress?:()=>void;
}> = ({ text, icon, color = 'primary', fill = 'solid', size = 'default',buttonOnPress }) => {
  return (
    <Styled>
    <IonButton className="abc" size={size} color={color} fill={fill}  onClick={ () => buttonOnPress?buttonOnPress():null }>
     <div className="tag_text">
     {text}
     </div> 
      {icon && <IonIcon className='search' icon={icon} slot='end' />}
    </IonButton>
    </Styled>
  );
};

const Styled = styled.div`

.tag_text{
  text-overflow: ellipsis;
  //width: 88px;
  overflow: hidden;
}

IonButton .button-inner {
  display: block !important;
  position: relative;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 72px !important;
  // height: 13px !important;
  z-index: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
    // display: block !important;
    // position: relative;
    // width: 72px;
    // height: 13px;
    // z-index: 1;
    // margin: 0 auto;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
  
`


export default Button;
