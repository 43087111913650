import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import Collection from '../../model/Collection';
import { getCollections,updateCollection,deleteCollectionItem} from '../../util/API';
import useTranslations from '../../util/useTranslation';
import CreateCard from '../common/CreateCard';
import Header from '../common/Header';
import ItemsList from '../common/Item/List';
import Button from '@material-ui/core/Button';
import { IonSpinner } from '@ionic/react';
import UploadNewItem from '../upload-new-Item/UploadNewItem';

const Collections = () => {
  const [t] = useTranslations('collections');
  const [collections, setCollections] = useState<Collection[]>([]);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [uploadModal,setuploadModal ] = useState<boolean>(false);
  let history=useHistory();

  const fetchCollections = async () => {
    setSpinner(true);
    const {
      data: { collections },
    } = await getCollections(true);
    setSpinner(false);

    setCollections(collections);
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  const _deleteItem= async (collectionId, itemId)=> {
  
    const collection:any = collections.find(task => task.id == collectionId);
    if (collection && collection.item_details && collection.item_details.results.length) {       
      var itemIds: any = [];
      for (var i=0; i < collection.item_details.results.length; i++) {
        if (collection.item_details.results[i].id !== itemId) {
          itemIds.push(collection.item_details.results[i].id)
        }
      }
      let request={
        id: collection.id,
        description: collection.description,
        title: collection.title,
        preview_url: collection.preview_url,
        items: itemIds,
        user_id: collection.user_id
      }
      await deleteCollectionItem(request);
      fetchCollections();
    }  
  }

  const uploadToCollection = (id: number) => (options: any) => {
    const { itemType } = options;

    return (
      <Button onClick={()=>setuploadModal(true)} style={{ backgroundColor:"transparent",textDecoration: 'none', order: -1 }}>
         <CreateCard>{t('collection.upload')}</CreateCard>
      </Button>

    );
  };

  return (
    <div className='container wide'>
      {spinner && (<div className="spinner">
        <IonSpinner color="primary" />
      </div>)}
      <Header>
        <h2>{t('header.headline')}</h2>
      </Header>
      <div className='collections'>
        {collections.map((collection) => (
          <ItemsList
            key={collection.id}
            itemType='collection'
            title={collection.title}
            items={collection.item_details?.results}
            t={t}
            add={uploadToCollection(collection.id)}
            deleteItem={(itemId) => _deleteItem(collection.id, itemId)}
          />
        ))}
      </div>
      {uploadModal && (
        <UploadNewItem viewUploadModal={uploadModal} closeUploadModal={() => setuploadModal(false)}/>
      )
      }
    </div>
  );
};

export default Collections;
