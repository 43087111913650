import { TFunction } from 'react-i18next';
import styled from 'styled-components';
import React, { SyntheticEvent, useState } from 'react';
import { Link} from 'react-router-dom';
import Item from '../../model/Item';
import {
  IonItem,
  IonList,
  useIonPopover,
  IonLabel,
  IonButton,
  IonRouterLink
} from '@ionic/react';
import CardFooter from './CardFooter';
import  { SaveItemActionsState } from '../common/SaveItem/Actions';


const RecommendCard: React.FC<{
  items: Item[],
  t: TFunction<string[]>,
  mapCard?: Boolean;
  itemClickedIndex?:(index: any) => any
}> = ({ items,t,mapCard,itemClickedIndex}) => {
    const saved = false;
  
    const [saveActionsState, setSaveActionsState] =
    useState<SaveItemActionsState>({
      event: undefined,
      dismiss: () =>
        setSaveActionsState((prev) => ({ ...prev, event: undefined })),
    });

  const showSaveActions = (e: SyntheticEvent) =>
    setSaveActionsState((prev) => ({
      ...prev,
      event: e.nativeEvent,
    }));
  return (
    <Styled>
      <div className='search-results recommended-cards-wrapper'>
        {items?.map((item, index) => {
          return (
            <>
            <ItemCardWrapper key={item.id} className="recommendedCard">      
                  <StyledItemCard className='shadow' style={{height:' 75%',padding: '1.2rem', gap:' 1.1rem'}} >
                   <img className='item-preview' src={item.preview_url} alt={item.title} />
                   <div className='item-details d-flex d-flex-col ' style={{marginTop: '10px'}}>
                    <div className='item-details-header d-flex' style={{visibility: 'visible'}}>
                      <div className='item-details-menu-toggler'  style={{marginLeft: 'inherit'}}>
                       <span className="book-btn">{item.type}</span>
                      </div>
                    </div>
                    <div className='item-title-wrapper hide'>
                    {/* <h4 className='item-title'> 
                        <Link className='item-title-link'
                          // to={`/app/search/items/${encodeURIComponent(item.id)}`}
                          to={`/app/search/item-detail/${encodeURIComponent(item.id)}`}
                        >{item.title}</Link>
                        
                    </h4> */}
                     <h4 className='item-title'>
                      {mapCard ? 
                      <IonLabel className='item-title-link'
                          onClick={() => itemClickedIndex? itemClickedIndex(index) : null}
                      >{item.title}</IonLabel> 
                      : 
                      <Link className='item-title-link'
                          // to={viewItem(item)}
                          to={`/app/search/item-detail/${encodeURIComponent(item.id)}`}
                          // to={`/app/search/items/${encodeURIComponent(item.id)}`}
                      >{item.title}</Link> }
                      
                    </h4>
                    </div>
                    <p className='item-details-description hide'>{item.description}</p>
                    <div className='item-details-footer d-flex ion-justify-content-between'>
                    <CardFooter item={item} t={t}></CardFooter>
                    </div>
                    </div>
                </StyledItemCard>
            </ItemCardWrapper>
            </>
          );
        })}
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  margin-top: 55px;
  margin-bottom: 70px;

  .search-results {
    display: flex;
    flex-wrap: wrap;
    gap: 2.3rem;
  }
`;

const ItemCardWrapper = styled.div`
  width: 29rem;
  height: 18.75rem;
  font-size: 1.375rem;
  line-height: 1.2;

  .item-details-header {
    visibility: hidden;
  }
`;

const StyledItemCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  gap: 1.5rem;

  h4,
  p {
    padding: 0;
    margin: 0;
  }

  .item {
    &-preview {
      width: 10rem;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    &-title {
      color: var(--ion-color-primary);
      font-size: inherit;
      line-height: inherit;
      font-weight: bold;
      word-wrap: break-word;

      &-wrapper {
        height: 2.5em;
        margin-bottom: 0.5em;
        flex-shrink: 0;
        max-width: 175px;
      }

      &-link {
        text-decoration: none;
      }
    }

    &-details {
      width: 100%;

      &-header {
        height: 1rem;
      }

      &-menu {
        &-toggler {
          font-size: 2rem;
          margin-left: auto;
          align-self: flex-end;
          color: var(--ion-color-secondary);
          cursor: pointer;
        }
      }

      &-description {
        color: var(--ion-color-secondary);
        font-size: 1rem;
        line-height: 1.25;
      }

      &-footer {
        height: 2rem;
        min-height: 2rem;
        margin-top: auto;
      }
    }
  }
`;
const ItemCardFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  justify-content: space-between;

  .read-more-link {
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const SaveItemButtonWrapper = styled.div`
  height: 100%;
  min-width: 50%;

  ion-button {
    --border-radius: 100px;
    text-transform: unset;
    font-size: inherit;
    font-weight: bold;
  }
`;
export default RecommendCard;

