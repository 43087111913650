import { heart } from 'ionicons/icons';
import React, { SyntheticEvent, useState } from 'react';
import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../common/Button';
import Item from '../../model/Item';
import SaveItemActions, { SaveItemActionsState } from '../common/SaveItem/Actions';

const ItemCardFooter: React.FC<{ item: Item;t: TFunction<string[]> }> = ({
  item,
  t
}) => {
  const [saveActionsState, setSaveActionsState] =
    useState<SaveItemActionsState>({
      item,
      event: undefined,
      dismiss: () =>
        setSaveActionsState((prev) => ({ ...prev, event: undefined })),
    });

  const showSaveActions = (e: SyntheticEvent) =>
    setSaveActionsState((prev) => ({
      ...prev,
      event: e.nativeEvent,
    }));

  // TODO change to check if saved
  const saved = false;
  return (
    <>
      <ItemCardFooterWrapper>
        <Link
          className='read-more-link'
          // to={`/app/collectionDetail/${encodeURIComponent(item.id)}`}
          to={`/app/search/item-detail/${encodeURIComponent(item.id)}`}
        >
          {t('results.more', { ns: 'search' })}
        </Link>

        <SaveItemButtonWrapper onClick={showSaveActions}>
          <Button
            text={t(`results.${saved ? 'saved' : 'save'}`, {
              ns: 'search',
            })}
            size='small'
            color='primary'
            fill={saved ? 'solid' : 'outline'}
            icon={heart}
          />
        </SaveItemButtonWrapper>

        
      </ItemCardFooterWrapper>
      <SaveItemActions {...saveActionsState} />
    </>
  );
};

const ItemCardFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  justify-content: space-between;

  .read-more-link {
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const SaveItemButtonWrapper = styled.div`
  height: 100%;
  // min-width: 50%;
  min-width: 60%;

  ion-button {
    --border-radius: 100px;
    text-transform: unset;
    font-size: inherit;
    font-weight: bold;
  }
`;

export default ItemCardFooter;

