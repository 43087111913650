import { IonIcon } from '@ionic/react';
import { arrowBack, heart } from 'ionicons/icons';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import Itemlist from './RecommendCard';
import Item from '../../model/Item';
import { IonSpinner } from '@ionic/react';
import { getItem, getRelatedItems } from '../../util/API';
import useTranslations from '../../util/useTranslation';
import NoImg from '../../assets/NoImg.png';
import Button from '../common/Button';
import SaveItemActions, {
SaveItemActionsState,
} from '../common/SaveItem/Actions';
import Header from '../common/Header';
import RoomIcon from '@material-ui/icons/Room';
import { NavLink } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { IonContent, IonModal} from '@ionic/react';

const CollectionItemDetail = () => {
const [t] = useTranslations('item', 'search');
const [showModal, setShowModal] = useState<boolean>(false)
const [item, setItem] = useState<any>();
const [spinner, setSpinner] = useState<boolean>(false);
const {items: filterItems } = t('languagefilter', {
  returnObjects: true,
}) as {
  items: Array<{ id: string; title: string; value: string }>;
};
  const [option,setOption]=useState<string>(t('option_initial'));
    const [relatedItems, setRelatedItems] = useState<Item[]>([]);
      const { goBack } = useHistory();
      const { params } = useRouteMatch<{ id?: any }>();

        useEffect(() => {
        setSpinner(true)
        const fetchItem = async () => {
        const {
        data: { results },
        } = await getItem(params.id as string);
        setSpinner(false)
        if (results.length) {
        setItem(results[0]);
        }
        };

        fetchItem();
        fetchFilteredData(option);
        }, [params.id]);

        useEffect(() => {
        const fetchRelatedItems = async () => {
        const {
        data: { results },
        } = await getRelatedItems(params.id);
        setRelatedItems(results && results.length ? results.slice(0, 4) : []);
        };

        if (params.id) {
        fetchRelatedItems();
        }

        setSaveActionsState((prev) => ({ ...prev, item }));
        }, [params]);

        const [saveActionsState, setSaveActionsState] =
        useState<SaveItemActionsState>({
          item,
          event: undefined,
          dismiss: () =>
          setSaveActionsState((prev) => ({ ...prev, event: undefined })),
          });
          const fetchFilteredData=(e)=>
          {
          //Display data according filter option click action with API call
          console.log("action clicked is",e);
          }
          const handleChange = (event) =>
          {
          setOption(event.target.value);
          fetchFilteredData(event.target.value);
          };
          const showSaveActions = (e: SyntheticEvent) =>
          setSaveActionsState((prev) => ({
          ...prev,
          event: e.nativeEvent,
          }));

          // TODO add logic to identify saved
          const saved = false;
          return (
          <Root>
            <main id='postDetails'>
            {spinner && (<div className="spinner">
             <IonSpinner color="primary" />
             </div>)}
              <div className='wide'>
                <Header>
                  <div className='preview hide'>
                    <img src={item?.preview_url} alt='big-img' className="img-fluid" />
                    <span className="search-btn" style={{cursor:'pointer'}} onClick={() => setShowModal(true)}>
                      <SearchIcon/>
                    </span>
                  </div>
                  <div className="card-description-wrap">
                    <div className="wrap">
                      <IonIcon icon={arrowBack} size='large' className="backarrow" onClick={goBack} />
                      <div className='custom-select-wrapper main-custom-select-wrap'>
                        <div className='custom-select-wrapper'>
                          <span style={{ marginRight: '15px' }}>
                          {t('description.text')} {' '}
                          </span>

                          <div className='select-box'>
                            <div className='select-box__current select_block' onChange={handleChange} tabIndex={1}>
                               {
                                filterItems?.map(({id,title,value },idx) => (
                                <div className='select-box__value'>
                                <input className='select-box__input' type='radio' id={id} value={value}
                                 name='customSelect' checked={option== value ? true : false} />
                                <p className='select-box__input-text'>{title}</p>
                                </div>
                               ))}
                              <img className='select-box__icon' src='http://cdn.onlinewebfonts.com/svg/img_295694.svg'
                                alt='Arrow Icon' aria-hidden='true' />
                            </div>

                            <ul className='select-box__list'>               
                                {filterItems?.map(({id,title},idx) => (
                                  <li>
                                  <label className='select-box__option' htmlFor={id}>
                                    {title}
                                  </label>
                                </li>))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='post-content-wrap'>
                      <ItemStyled>
                        <div className='details'>
                          <div className='book-wrapper'>
                            <div className='book'>
                              <span>{item?.type}</span>
                            </div>
                            <div>
                              <img src='http://localhost:3002/static/media/jstory.6b56adc2.svg' className='logo-img'
                                alt='' />
                            </div>
                          </div>
                          <h4 className='title'>{item?.title}</h4>
                          {item?.location && <div className='address-nav'>
                            <span>
                              <RoomIcon />
                            </span>
                            <span style={{ paddingTop: '5px' ,display:'flex' }}>
                              {item?.location[0].label}
                              <NavLink to='#' className='title_icon' onClick={() => (item?.location) ? window.open(`http://maps.google.com/maps?z=12&t=m&q=loc:${item?.location[0].lat}+${item?.location[0].lon}`,"_blank"): null}>
                               {t('viewmap.text')}
                              </NavLink>
                            </span>
                          </div>}
                          <div className='d-flex description-para-wrap'>
                            <p className='description para'>
                              {item?.description}
                            </p>
                          </div>
                          <div className='heading-text-wrap d-flex'>
                            <div className='provider'>
                              <span>{t('provider.text')}</span>
                              <span className='d-flex' style={{ marginTop: '15px' }}>
                                <RoomIcon />
                                <span className='text-blue' style={{cursor: 'pointer'}} onClick={() => (item?.url) ? window.open(item?.url ,"_blank"): null}>{item?.provider}</span>
                              </span>
                            </div>
                            <div className='original-text-wrap'>
                              <img src="https://jhn.ngo/assets/img/logos/JHM.png" alt="" />
                            </div>
                          </div>
                          <div className="d-flex bottom-content-wrap">
                            <div className='original-text-wrap'>
                              <img style={{cursor: 'pointer'}} src={item?.rights.icon} alt="" onClick={() => window.open(item?.rights.url,"_blank")} />
                            </div>
                            <div className='actions' style={{ padding: '44px 0px' }}>
                              <SaveItemButtonWrapper onClick={showSaveActions}>
                                <Button text={t(`results.${saved ? 'saved' : 'save' }`, { ns: 'search' , })}
                                  color='primary' fill={saved ? 'solid' : 'outline' } icon={heart} />
                              </SaveItemButtonWrapper>
                            </div>
                          </div>
                        </div>
                      </ItemStyled>

                    </div>
                  </div>
                </Header>
                {/* {item && ( */}
                <>
                  <div className='container'>
                    <RelatedItems style={{ margin: '40px 0px' }}>
                      <div style={{display:'flex',justifyContent:'space-between'}}>  
                      <h4>{t('item.related')}</h4>
                      {item?.tags.length ?
                      <div className='tag-list'>
                        {item?.tags.map((tag) => (
                          <span>{tag}</span>
                        ))}
                      </div> :
                       <div style={{color:"black",fontSize:17}}>{t('notags')}</div>
                      }
                      </div>

                      <Itemlist t={t} items={relatedItems}></Itemlist>
                    </RelatedItems>
                    <SaveItemActions {...saveActionsState} />
                  </div>
                </>
              </div>
            </main>

            {showModal && <IonContent>
              <Modal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <img src={item?.preview_url} alt='big-img'/>
                <ClearIcon onClick={() => setShowModal(false) }>
                </ClearIcon>
              </Modal>
              </IonContent>}
          </Root>
          );
          };

          const Root = styled.div`
          // margin-top: 92px;

          // @media only screen and (max-width: 1440px) {
          //   --margin-top: 702px;
          // }

          ion-icon {
          cursor: pointer;
          font-size: 25px !important;
          }
          `;

          const ItemStyled = styled.div`
          margin-top: 2rem;
          margin-bottom: 7rem;
          display: flex;

          .details {
          width: 100%;
          }

          .preview {
          flex-shrink: 0;
          border-radius: 1rem;
          width: 16.25rem;
          height: 25rem;
          margin-right: 4rem;

          img {
          height: 100%;
          object-fit: cover;
          }
          }

          .title {
          margin: 0;
          color: var(--ion-color-primary);
          font-size: 3rem;
          font-weight: bold;
          margin-bottom: 2.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 460px;
          }

          .description {
          margin: 0;
          color: var(--ion-color-secondary);
          font-size: 1.5rem;
          width: 52%;
          margin-right: 15%;
          }

          .actions {
          display: flex;
          align-items: center;s
          }
          `;

          const SaveItemButtonWrapper = styled.div`
          margin-right: 1.375rem;

          ion-button {
          --border-radius: 100px;
          text-transform: capitalize;
          font-weight: bold;
          font-size: 1rem;
          }
          `;

          const Collections = styled.div`
          text-transform: uppercase;
          color: var(--ion-color-primary);
          font-size: 0.875rem;
          font-weight: 600;
          `;

          const Tags = styled.div`
          h4 {
          margin: 0;
          font-size: 1.25rem;
          font-weight: bold;
          margin-top: 20px;
          margin-bottom: 1.5rem;
          padding-left: 57px;
          color: var(--ion-color-secondary);
          }

          .tags {
          display: flex;
          flex-wrap: wrap;
          margin-left:20px
          }
          `;

          const RelatedItems = styled.div`
          h4 {
          margin: 0;
          margin-bottom: 1.5rem;
          text-transform: uppercase;
          font-size: 1.25rem;
          font-weight: bold;
          color: var(--ion-color-dark);
          }
          `;

          const TagButtonWrapper = styled.div`
          ion-button {
          --border-radius: 100px;
          font-size: 0.875rem;

          `;

          const Modal = styled(IonModal)`
           --height: 85vh;
          --width: 85vw;
          --border-radius: 0.25rem;
          --position:relative;
          
          a,button,select,li,svg{
            cursor: pointer;
          }
        
          .item-details-footer>div .chooseMe{
            text-align: right;
          }
          img{
            max-width: inherit;
            border: 0;
            height: 100vh;
            object-fit: contain;
          }
          svg{
            position: absolute;
            top: 10px;
            right: 0;
            z-index: 1;
            color: #fff;
            right: 10px;
          }


          `;

         

          export default CollectionItemDetail;