import { IonHeader, IonToolbar } from '@ionic/react';
import { add } from 'ionicons/icons';
import React from 'react';
import { TFunction } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import logo from '../../assets/logo/jstory.svg';
import Button from './Button';

const NavBar: React.FC<{ t: TFunction<string[]> ,hideForRoute:any}> = ({ t,hideForRoute }) => {
  const { path } = useRouteMatch();
  let history=useHistory();
  return (
    <div>
      {
        hideForRoute && hideForRoute.indexOf(history.location.pathname)>-1?'':
        <IonHeader className='ion-no-border'>
        <IonToolbar>
          <Styled>
            <div className='container wide'>
              <ul className='navigation'>
                <li className='brand'>
                  <NavLink to='/app'>
                    <img src={logo} alt='J-Story' />
                  </NavLink>
                </li>
                <li>
                  <ul>
                    <li>
                      <NavLink className='list-item' to={`${path}/dashboard`}>
                        {t('common.nav.dashboard', { ns: 'common' })}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='list-item' to={`${path}/search`}>
                        {t('common.nav.search', { ns: 'common' })}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className='list-item' to={`${path}/collection`}>
                        {t('common.nav.collections', { ns: 'common' })}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className='user-actions'>
                <li>
                  <CreateButtonWrapper>
                  <NavLink to={`${path}/new-experience`}>
                    <Button
                        text={t('common.userActions.createNew', { ns: 'common' })}
                        color='warning'
                        icon={add}
                      />
                  </NavLink>
                  </CreateButtonWrapper>
                </li>
                <li>
                  <input type='checkbox' id='user-menu-checkbox' />
                  <label
                    htmlFor='user-menu-checkbox'
                    className='user-menu list-item'
                  >
                    <span className='avatar'>
                      <img src="https://jhn.ngo/assets/img/team/2.png"></img>
                    </span>
                    {t('common.username', { ns: 'common' })}
                  </label>
                </li>
              </ul>
            </div>
          </Styled>
        </IonToolbar>
      </IonHeader>
      }
    </div>
   
  );
};

const Styled = styled.nav`
  .container {
    min-height: 100px;
    display: flex;
    justify-content: space-between;
  }

  h2,
  p {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }

  li + li {
    margin-left: clamp(0.6rem, 5vw, 4rem);
  }

  .list-item {
    padding: 0.5rem 0;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    color: var(--ion-color-secondary);
    font-weight: bold;
  }

  .list-item {
    &.active {
      color: var(--ion-color-primary);
      border-color: var(--ion-color-primary);
    }
  }

  .navigation {
    .brand {
      width: 9rem;

      * {
        width: 100%;
      }
    }

    .list-item {
      font-size: 1.125rem;
    }
  }

  #user-menu-checkbox {
    display: none;

    &:checked {
      & ~ label {
        color: red;

        .avatar {
          background-color: red;
        }
      }
    }
  }

  .avatar {
    margin-right: 0.5em;
    display: inline-block;
    vertical-align: middle;
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--ion-color-medium);
    border-radius: 50%;
  }
`;

const CreateButtonWrapper = styled.div`
  ion-button {
    --border-radius: 100px;
    text-transform: unset;
    font-size: 1rem;
    font-weight: bold;
  }
`;

export default NavBar;
