import { IonLabel, IonToggle } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';

const ToggleButton : React.FC<{
    text?:string;
    color?: 'primary' | 'secondary' | 'danger' | 'light' | 'dark';
    checked: boolean;
    toggleChange?:(checked:any)=> void;
}> =({text, checked, color="primary", toggleChange}) => {
    return (
        <Root>
            <IonToggle checked={checked} mode={'md'} onIonChange={e => toggleChange? toggleChange(e.detail.checked):false}>
            </IonToggle>
            <IonLabel>{text}</IonLabel>
        </Root>
        
        
    )
}

const Root = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;

ion-toggle {
    height: 18px;
    width: 40px;

    --background: #0849ff;
    --background-checked: #0849ff;

    --handle-background: #fff;
    --handle-background-checked: #fff;

    // --border-radius: 10px;
    // --handle-border-radius: 10px;
    // --handle-box-shadow: 10px;

    --handle-height: 16px;
    --handle-width: 15px;
    --handle-spacing:2px;
    }
`

export default ToggleButton;