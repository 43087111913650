import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Section from './sections/Model';

const Footer = () => {
  const { t } = useTranslation(['home']);

  const { headline, subHeadline } = t('footer', {
    returnObjects: true,
  }) as Section;

  return (
    <StyledFooter id='footer'>
      <div className='container ion-text-center'>
        <div className='headline'>
          <span>{headline} </span>
          <a className='contact-mail' href='mailto:infoi@jhn.ngo'>
            {t('mailto')}
          </a>
        </div>
        <div className='subHeadline'>
          <span>{subHeadline} </span>
          {/* TODO own Terms of service page  */}
          <a
            className='tos-link'
            href='https://yahad.net/terms-of-service'
            target='blank'
          >
            {t('terms_service')}
          </a>
        </div>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background-color: var(--ion-color-dark);
  padding: 2em 0;

  .headline {
    color: var(--ion-color-dark-contrast);
    font-size: 1.625em;
    font-weight: bold;
  }

  .contact-mail {
    color: var(--ion-color-warning);
    text-decoration: none;
  }

  .subHeadline {
    margin-top: 1.25em;
    color: var(--ion-color-light);
    font-size: 1em;
  }

  .tos-link {
    color: var(--ion-color-light);
  }
`;

export default Footer;
