import { startCase } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import useQuery from '../../util/useQuery';
import Button from '../common/Button';

const FilterItem: React.FC<{ value: string; filterKey: string }> = ({
  value,
  filterKey,
}) => {
  const { toggleFilter, isActive } = useQuery(filterKey, value);

  return (
    <span id="tag-btn-wrapper">
    <FilterButtonWrapper onClick={toggleFilter}>
      <Button
        color={isActive() ? 'primary' : 'light'}
        text={startCase(value)}
        size='small'
      />
    </FilterButtonWrapper>
    </span>
  );
};

const FilterButtonWrapper = styled.div`

  ion-button {
    --border-radius: 100px;
    font-size: 0.875rem;
    text-transform: unset;
  }
  ion-button .button-inner {
    display: block !important;
    position: relative;
    flex-flow: row nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 72px !important;
    height: 13px !important;
    z-index: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color:#000;
  }
`;

export default FilterItem;
