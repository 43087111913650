import { menuController } from '@ionic/core';
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import NavMenuComponentProps from '../../interfaces/NavMenuComponentProps';

const MobileNavMenu: React.FC<NavMenuComponentProps> = ({
  navMenuItems,
  scrollToSection,
}) => {
  const { t } = useTranslation(['home']);
  return (
    <IonMenu side='end' menuId='homeMenu' contentId='homeContent'>
      <IonHeader>
        <IonToolbar color='primary'>
          <IonTitle>{t('startmenu')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {navMenuItems.map(({ id, text }) => (
            <IonItem
              key={`mobile-menu-item-${id}`}
              onClick={() => {
                menuController.close();
                scrollToSection(id);
              }}
            >
              {text}
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default MobileNavMenu;
