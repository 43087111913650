import queryString from 'query-string';
import { useLocation } from 'react-router';

import ChooseContentItem from '../common/SaveItem/ChooseContentItem';
import useTranslations from '../../util/useTranslation';
import styled from 'styled-components';

const SearchWidget=()=>
{
    const [t] = useTranslations('item', 'search');
    const location = useLocation();
    let params = queryString.parse(location.search);

    return(
        <Styled>
        <div>
            <ChooseContentItem showModal={false} 
                            closeModal={(data)=>console.log("DATA IS",data)}
                            onSelect={(item: any) => {
                                    window.parent.postMessage({ message: params['id'], value: [item] }, "*");

                            }}>

            </ChooseContentItem>
        </div>
        </Styled>
    )
}
export default SearchWidget;

const Styled = styled.div`
@media only screen and (max-width: 1313px){
    .p-multiselect.p-component.p-inputwrapper{
      margin-top: 16px !important;
      margin-right: 12px !important;
    }
  }

`;

