import styled from 'styled-components';
import useTranslations from '../../../util/useTranslation';
import CommonSection from './Common';
import Section from './Model';

const { PUBLIC_URL } = process.env;

const HowDoneSection: React.FC = () => {
  const id = 'how-done';
  const [t] = useTranslations('home');

  const { headline, items } = t(id, {
    returnObjects: true,
  }) as Section;

  return (
    <StyledSection id={id}>
      <div className='container'>
        <h2 className='headline ion-text-center'>{headline}</h2>
        <div className='items'>
          {items.map(({ title, description }, idx) => (
            <div
              className='item d-flex d-flex-col ion-align-items-center ion-text-center'
              key={`${id}-section-item-${idx}`}
            >
              <h3 className='title'>{title}</h3>
              <p className='description'>{description}</p>
            </div>
          ))}
        </div>
      </div>
    </StyledSection>
  );
};

const StyledSection = styled(CommonSection)`
  background: url('/assets/how-done/bg.png');

  .headline {
    color: var(--ion-color-warning);
  }

  .item {
    color: var(--ion-color-primary-contrast);
  }

  .item + .item {
    border-top: 1px solid var(--ion-color-light);
  }

  @media screen and (min-width: 1024px) {
    .headline {
      margin: 0;
    }

    .item {
      flex-direction: row;
      padding: 2.5em 0;
      justify-content: space-evenly;

      .title {
        width: 15%;
        white-space: pre-wrap;
        text-align-last: left;
      }

      .description {
        width: 65%;
        text-align: left;
      }
    }

    .item + .item {
      margin: 0;
    }
  }
`;

export default HowDoneSection;
