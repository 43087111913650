import React, { useState } from 'react';
import styled from 'styled-components';
import Item from '../../../model/Item';
import { useHistory,Link,Router} from 'react-router-dom';
import { ItemType } from '../../../model/ItemType';
import useTranslations from '../../../util/useTranslation';
import {
  IonItem,
  IonList,
  useIonPopover,
  IonLabel,
  IonButton,
  IonRouterLink
} from '@ionic/react';
import { cursorTo } from 'readline';
import './Card.css'

const PopoverList: React.FC<{
  onHide: () => void;
  item: Item;
  history:any,
  collectionId:any;
  onItemDelete?: () => void;
  colKey?:number,
  itemtype:ItemType;
  translate: any[];

}> = ({ onHide,itemtype,history,collectionId, item, translate,colKey,onItemDelete }) => {
      const [t] = translate;
      return (
        <StyledMenu>
          <IonList lines='none'>         
            {/* <IonItem><a onClick={onHide} href={`/app/search/items/${encodeURIComponent(item.id)}`} target="_blank">{t('menu.view')}</a></IonItem> */}
            <IonItem className="ionItem" href={item.view_url ? item.view_url: `/app/search/items/${encodeURIComponent(item.id)}`}>
            {t('menu.view')}    
            </IonItem>
            <IonItem className="ionItem" onClick={()=>{onHide();history.push({pathname:`/app/${itemtype}`,state:{data:item,id:item.id}})}}>
            {t('menu.edit')}     
            </IonItem> 
            <IonItem className="ionItem" onClick={()=>
             ( 
              onItemDelete?onItemDelete():null,
              onHide()
             )
              }>    
            {t('menu.delete')}
            </IonItem> 
          </IonList>          
         </StyledMenu>
    );
  }

const StyledMenu = styled.div`
  a {
    text-decoration: none;
    color: var(--ion-color-secondary);
  }
`;

const ItemCard: React.FC<{
  item: Item;
  itemtype?:ItemType;
  mapCard?: Boolean;
  itemClicked?:() => void;
  deleteItem?:(id : any) =>void;
  viewItem?:(item : Item) =>any;
  footer?: JSX.Element;
}> = ({ item,itemtype, footer, mapCard, itemClicked ,deleteItem, viewItem=(item) => `/app/search/item-detail/${encodeURIComponent(item.id)}`}) => {
  const history=useHistory();
  // deleteItem ? deleteItem(item.id) : null
  const [present, dismiss] = useIonPopover(PopoverList, {onItemDelete: ()=> deleteItem ? deleteItem(item.id) : null,onHide: () => dismiss(),history:history, item: item,itemtype:itemtype, translate: useTranslations('item') });


  return (
    <StyledItemCard className='shadow'>
      <img className='item-preview' src={item.preview_url} alt={item.title} />
      <div className='item-details d-flex d-flex-col hide'>
        <div className='item-details-header d-flex'>
          <div
            onClick={(e) =>
              present({
                event: e.nativeEvent,
              })
            }
            className='item-details-menu-toggler'
          >
            ...
          </div>
        </div>
        <div className='item-title-wrapper hide'>
          <h4 className='item-title'>
            {mapCard ? 
            <IonLabel className='item-title-link'
                onClick={() => itemClicked? itemClicked() : null}
                // onClick={() => alert('Clicked')}
            >{item.title}</IonLabel> 
            : 
            <Link className='item-title-link'
                to={viewItem(item)}
            >{item.title}</Link> }
             
          </h4>
        </div>
        <p className='item-details-description hide'>{item.description}</p>
        <div className='item-details-footer d-flex ion-justify-content-between'>
          {footer}
        </div>
      </div>
    </StyledItemCard>
  );
};

const StyledItemCard = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  gap: 1.5rem;

  h4,
  p {
    padding: 0;
    margin: 0;
  }

  .item {
    &-preview {
      width: 10rem;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    &-title {
      color: var(--ion-color-primary);
      font-size: inherit;
      line-height: inherit;
      font-weight: bold;
      word-wrap: break-word;

      &-wrapper {
        height: 2.5em;
        margin-bottom: 0.5em;
        flex-shrink: 0;
      }

      &-link {
        text-decoration: none;
      }
    }

    &-details {
      width: 100%;

      &-header {
        height: 1rem;
      }

      &-menu {
        &-toggler {
          font-size: 2rem;
          margin-left: auto;
          align-self: flex-end;
          color: var(--ion-color-secondary);
          cursor: pointer;
        }
      }

      &-description {
        color: var(--ion-color-secondary);
        font-size: 1rem;
        line-height: 1.25;
      }

      &-footer {
        height: 2rem;
        min-height: 2rem;
        margin-top: auto;
      }
    }
  }
`;

export default ItemCard;
