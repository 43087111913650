import { IonIcon } from '@ionic/react';
import { arrowBack, heart } from 'ionicons/icons';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import Item from '../../model/Item';
import { getItem, getRelatedItems } from '../../util/API';
import useTranslations from '../../util/useTranslation';
import Button from '../common/Button';
import SaveItemActions, {
  SaveItemActionsState,
} from '../common/SaveItem/Actions';
import SearchResultItemsList from '../search/ItemsList';

const ItemDetails = () => {
  const [t] = useTranslations('item', 'search');
  const [item, setItem] = useState<Item>();
  const [relatedItems, setRelatedItems] = useState<Item[]>([]);

  const { goBack } = useHistory();
  const { params } = useRouteMatch<{ id?: string }>();

  useEffect(() => {
    const fetchItem = async () => {
      const {
        data: { results },
      } = await getItem(params.id as string);

      if (results.length) {
        setItem(results[0]);
      }
    };

    fetchItem();
  }, [params.id]);

  useEffect(() => {
    const fetchRelatedItems = async () => {
      const {
        data: { results },
      } = await getRelatedItems(item!.id);
      setRelatedItems((results && results.length) ? results.slice(0, 4) : []);
    };

    if (item?.id) {
      fetchRelatedItems();
    }

    setSaveActionsState((prev) => ({ ...prev, item }));
  }, [item]);

  const [saveActionsState, setSaveActionsState] =
    useState<SaveItemActionsState>({
      item,
      event: undefined,
      dismiss: () =>
        setSaveActionsState((prev) => ({ ...prev, event: undefined })),
    });

  const showSaveActions = (e: SyntheticEvent) =>
    setSaveActionsState((prev) => ({
      ...prev,
      event: e.nativeEvent,
    }));

  // TODO add logic to identify saved
  const saved = false;

  return (
    <Root>
      <div className='container wide'>
        <IonIcon icon={arrowBack} size='large' onClick={goBack} />
        {item && (
          <>
            <ItemStyled>
              <div className='preview hide'>
                <img src={item.preview_url} alt='' />
              </div>
              <div className='details'>
                <h4 className='title'>{item.title}</h4>
                <div className='d-flex'>
                  {item.description && (
                    <p className='description'>{item.description}</p>
                  )}
                  {!!item.tags?.length && (
                    <Tags>
                      <h4>{t('item.tags')}</h4>
                      <div className='tags'>
                        {item.tags?.map((tag) => (
                          <TagButtonWrapper key={tag}>
                            <Button text={tag} color='primary' size='small' />
                          </TagButtonWrapper>
                        ))}
                      </div>
                    </Tags>
                  )}
                </div>
                <div className='actions'>
                  <SaveItemButtonWrapper onClick={showSaveActions}>
                    <Button
                      text={t(`results.${saved ? 'saved' : 'save'}`, {
                        ns: 'search',
                      })}
                      color='primary'
                      fill={saved ? 'solid' : 'outline'}
                      icon={heart}
                    />
                  </SaveItemButtonWrapper>
                  <Collections>
                    {/* TODO fix me */}
                    <div className='collection'>"{item.title}" {t('item.collection')}</div>
                  </Collections>
                </div>
              </div>
            </ItemStyled>
            {!!relatedItems?.length && (
              <RelatedItems>
                <h4>{t('item.related')}</h4>
                <SearchResultItemsList items={relatedItems} t={t} />
              </RelatedItems>
            )}
            <SaveItemActions {...saveActionsState} />
          </>
        )}
      </div>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 92px;

  ion-icon {
    cursor: pointer;
    font-size: 25px !important;
  }
`;

const ItemStyled = styled.div`
  margin-top: 2rem;
  margin-bottom: 7rem;
  display: flex;

  .details {
    width: 100%;
  }

  .preview {
    flex-shrink: 0;
    border-radius: 1rem;
    width: 16.25rem;
    height: 25rem;
    margin-right: 4rem;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    margin: 0;
    color: var(--ion-color-primary);
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2.5rem;
  }

  .description {
    margin: 0;
    color: var(--ion-color-secondary);
    font-size: 1.5rem;
    width: 52%;
    margin-right: 15%;
  }

  .actions {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
  }
`;

const SaveItemButtonWrapper = styled.div`
  margin-right: 1.375rem;

  ion-button {
    --border-radius: 100px;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1rem;
  }
`;

const Collections = styled.div`
  text-transform: uppercase;
  color: var(--ion-color-primary);
  font-size: 0.875rem;
  font-weight: 600;
`;

const Tags = styled.div`
  h4 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: var(--ion-color-secondary);
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
  }
`;

const RelatedItems = styled.div`
  h4 {
    margin: 0;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--ion-color-dark);
  }
`;

const TagButtonWrapper = styled.div`
  ion-button {
    --border-radius: 100px;
    font-size: 0.875rem;
  
`;

export default ItemDetails;
