import { useHistory, useLocation } from 'react-router';

export const QUERY_KEY = 'query';
export const GEOLOCATION_KEY = 'geolocated';

const useQuery = (filterKey: string, value: string = '') => {
  const location = useLocation();
  const history = useHistory();

  const searchParams = () => new URLSearchParams(location.search);

  const getQuery = () => searchParams().get(QUERY_KEY) as string;

  const setQuery = (query: string) => {
    const actualQuery = searchParams();

    if (query) {
      actualQuery.set(QUERY_KEY, query);
    } else {
      actualQuery.delete(QUERY_KEY);
      actualQuery.delete(GEOLOCATION_KEY);
    }

    history.push({ search: actualQuery.toString() });
  };

  const getGeoLocation = () => searchParams().get(GEOLOCATION_KEY) as string;

  const setGeoLocation = (location : string) => {
    const actualQuery = searchParams(); 

    if (location) {
      actualQuery.set(GEOLOCATION_KEY, location)
    } else {
      actualQuery.delete(GEOLOCATION_KEY);
    }
    console.log("")
    history.push({ search: actualQuery.toString() });
  }

  const filtersApplied = (): boolean => {
    const currentParams = searchParams();

    let result = false;
    currentParams.forEach((v, k) => {
      if (k !== QUERY_KEY && k !== GEOLOCATION_KEY) {
        result = true;
      }
    });

    return result;
  };

  const toggleFilter = (): void => {
    const currentParams = searchParams();
    const newParams = new URLSearchParams();

    currentParams.forEach((v, k) => {
      // Appending every pair except the scoped one
      if (!(k === filterKey && v === value)) {
        newParams.append(k, v);
      }
    });

    // Appending scoped pair if it wasn't present in actual query
    if (!currentParams.getAll(filterKey).includes(value)) {
      newParams.append(filterKey, value);
    }
    // localStorage.setItem("queryString",newParams.toString())
    history.push({ search: newParams.toString() });
  };

  const resetAllFilters = (): void => {
    const newParams = new URLSearchParams();
    const query = getQuery();

    if (query) {
      newParams.set(QUERY_KEY, query);
    }

    history.push({ search: newParams.toString() });
  };

  const isActive = (): boolean =>
    searchParams().getAll(filterKey).includes(value);

  return {
    filtersApplied,
    toggleFilter,
    resetAllFilters,
    searchParams,
    getQuery,
    setQuery,
    isActive,
    setGeoLocation,
    getGeoLocation
  };
};

export default useQuery;
