import { IonItem, IonList, IonListHeader, IonPopover } from '@ionic/react';
import React, { Dispatch, SetStateAction } from 'react';
import useTranslations from '../../../util/useTranslation';

export type MenuSelection = 'new' | 'existing' | 'chooseContent' | undefined | null;

export interface SaveMenuState {
  event?: Event;
  dismiss: () => void;
  menuSelection: MenuSelection;
  setMenuSelection: Dispatch<SetStateAction<MenuSelection>>;
}

const SaveItemMenu: React.FC<SaveMenuState> = ({
  event,
  dismiss,
  menuSelection,
  setMenuSelection,
}) => {
  const [t] = useTranslations('save-item');

  return (
    <>
      <IonPopover
        isOpen={!!event && !menuSelection}
        event={event}
        onDidDismiss={dismiss}
      >
        <IonList lines='none'>
          <IonListHeader lines='full'>{t('menu.header')}</IonListHeader>
          <IonItem button onClick={() => {
            setMenuSelection('new');
            dismiss()
          }}>
            {t('menu.new')}
          </IonItem>
          <IonItem
            button
            onClick={() => {
              setMenuSelection('existing');
              dismiss();
            }}
          >
            {t('menu.existing')}
          </IonItem>
        </IonList>
      </IonPopover>
    </>
  );
};

export default SaveItemMenu;
