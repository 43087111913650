import styled from 'styled-components';

const CommonSection = styled.section`
  padding: clamp(3.5em, 5vw, 5em) 0;

  .headline {
    font-size: 1.625em;
    font-weight: bold;
    margin: 0;
    margin-bottom: clamp(1.7em, 5vw, 2.5em);
  }

  .item {
    .title {
      font-size: 1.5em;
      font-weight: bold;
      margin: 1.18em 0 0.77em;
    }

    .description {
      font-size: 1.375em;
      margin: 0;
    }
  }

  .item + .item {
    margin-top: 2.9em;
  }
`;

export default CommonSection;
